import { IconButton } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const BackButton = ({ className })=>{
    const history = useHistory();
    const handleClick = ()=>{
        history.goBack();
    };
    return (
        <IconButton onClick={handleClick} className={className}>
            <AiOutlineArrowLeft/>
        </IconButton>
    );
};

export default BackButton;
