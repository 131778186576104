import { baseQuerySetup,getResponsedata,getResponseResult,onQueryStarted, onCacheEntryAdded , transformResponse } from 'config/index';

import { createApi } from '@reduxjs/toolkit/query/react';
import { setNavLoader } from './App.service';
let dashboardReducer = 'DashboardReducer';
let dashboardTag = 'DashboardTag';
let newUsersTag = 'NewUsersTag';
let INVITATION_TAG = 'INVITATION_TAG';
export const dashboardApi = createApi({
    reducerPath: dashboardReducer,
    baseQuery: baseQuerySetup,
    tagTypes: [dashboardTag, INVITATION_TAG],
    endpoints: (builder) => ({
        getListOfInvitations: builder.query({
            query: (USERTYPE)=>{
                if (USERTYPE) {
                    return { url: '/invitation?role=' + USERTYPE, method: 'GET' };
                }
                return { url: '/invitation', method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: (response)=>{
                return response.result.data;
            },
            providesTags: [INVITATION_TAG]
        }),
        InviteUsers: builder.mutation({
            query: (body) => {
                return { url: '/invitation/send-multiple', method: 'POST', body };
            },
            async onQueryStarted({ invitedToRole }, { dispatch , queryFulfilled }) {
                try {
                    dispatch(setNavLoader(true));
                    const { data } = await queryFulfilled;
                    dispatch(dashboardApi.util.updateQueryData('getListOfInvitations',
                        '' ,
                        (draftPost) => {
                            return [...data , ...draftPost];
                        }));
                    dispatch(dashboardApi.util.updateQueryData('getListOfInvitations',
                        invitedToRole ,
                        (draftPost) => {
                            return [...data , ...draftPost];
                        }));
                    if (data) {
                        dispatch(setNavLoader(false));
                    }
                } catch (error) {
                    dispatch(setNavLoader(false));
                }
            },
            onCacheEntryAdded,
            transformResponse
        }),
        ResentInvite: builder.mutation({
            query: (invitation) => {
                return { url: '/invitation/' + invitation._id + '/resend', method: 'POST' };
            },
            async onQueryStarted({ _id , invitedToRole }, { dispatch , queryFulfilled }) {
                try {
                    dispatch(setNavLoader(true));
                    const data = await queryFulfilled;
                    dispatch(dashboardApi.util.updateQueryData('getListOfInvitations',
                        '' ,
                        (draftPost) => {
                            let record = draftPost.find((row)=> row._id === _id);
                            record.updatedAt = new Date();
                        }));
                    dispatch(dashboardApi.util.updateQueryData('getListOfInvitations',
                        invitedToRole ,
                        (draftPost) => {
                            let record = draftPost.find((row)=> row._id === _id);
                            record.updatedAt = new Date();
                        }));

                    if (data) {
                        dispatch(setNavLoader(false));
                    }
                } catch (error) {
                    dispatch(setNavLoader(false));
                }
            },
            onCacheEntryAdded,
            transformResponse
        }),
        getDashboardstats: builder.query({
            query: () => {
                return { url: '/dashboard/stats' , method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponsedata,
            providesTags: [dashboardTag]
        }),
        getNewUsers: builder.query({
            query: () => {
                return { url: '/dashboard/new-users' , method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponseResult,
            providesTags: [newUsersTag]
        })
    })
});
export const { useGetDashboardstatsQuery,useGetNewUsersQuery, useGetListOfInvitationsQuery , useResentInviteMutation , useInviteUsersMutation } = dashboardApi;
