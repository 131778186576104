import { baseQuerySetup,transformResponse, onQueryStarted, onCacheEntryAdded } from 'config/index';
import { createApi } from '@reduxjs/toolkit/query/react';

let authReducer = 'LENDERREDUCER';
let authTag = 'LenderTag';

export const lenderApies = createApi({
    reducerPath: authReducer,
    baseQuery: baseQuerySetup,
    tagTypes: [authTag],
    endpoints: (builder) => ({
        getCompanyLenders: builder.query({
            query: () => {
                return { url: 'login', method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse
        })
    })
});

export const { useGetCompanyLendersQuery } = lenderApies;
