import React from 'react';
import { Text , useDisclosure } from '@chakra-ui/react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { viewDate } from 'utils/date';
import { APPROVE_ACTION , REJECTE_ACTION } from 'constants/admin-actions';
import Companyactionmodel from 'components/Model/Companyactionmodel';

import { COMPANY_APPOVED_STATUS } from 'constants/action.status';
import 'assets/css/Preview.css';
import { PENDING_STATUS } from 'constants/marketplace-default';

const ApproveButton = ({ handleClick })=>{
    return (
        <Text color={'green'} cursor={'pointer'} onClick={handleClick} colorScheme='brand'>
            Approve
        </Text>
    );
};
const RejectButton = ({ handleClick })=>{
    return (
        <Text onClick={handleClick} cursor={'pointer'} colorScheme='red' color='red'>
            Reject
        </Text>
    );
};

export default function Previewrequest({ data }) {
    const companymodelUtil = useDisclosure();
    const { onOpen } = companymodelUtil;
    const [Action , setAction] = React.useState(APPROVE_ACTION);

    const onApproveCompany = (rowdata)=>{
        setAction(APPROVE_ACTION);
        onOpen();
    };
    const onRejectCompany = (rowdata)=>{
        setAction(REJECTE_ACTION);
        onOpen();
    };

    return (
        <React.Fragment>
            <Companyactionmodel ACTION={Action} row={data} {...companymodelUtil} />

            <div className='preview-box'>
                <div className="preview-heading">
                    <p>Preview  Request </p>
                </div>
                <div className='preview-body' >
                    <table>
                        <tr>
                            <td>Ticket Id</td>
                            <td>
                                <Link to={'/organization/company/view-company/' + data?.companyId} color={'brand.500'}>
                                    {data?.ticketId}
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>Company Name</td>
                            <td>
                                <Link to={'/organization/company/view-company/' + data?.companyId} color={'brand.500'}>
                                    {data?.companyName}
                                </Link>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Type
                            </td>
                            <td>
                                {data?.type}
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Status
                            </td>
                            <td className={data?.status === COMPANY_APPOVED_STATUS ? 'success-status' : 'rejected-status'} >
                                {data?.status}
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Message from lender
                            </td>
                            <td>
                                {data?.message}
                            </td>
                        </tr>

                        <tr>
                            <td>
                            Created On
                            </td>
                            <td>
                                {viewDate(data?.createdAt)}
                            </td>
                        </tr>
                        {
                            data?.status === PENDING_STATUS && <tr>
                                <td>
                                    Actions
                                </td>
                                <td className='start-box'>
                                    <ApproveButton handleClick={onApproveCompany} />
                                    &nbsp;
                                    <RejectButton handleClick={onRejectCompany} />
                                </td>
                            </tr>
                        }
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}
