import React from 'react';
import { AiFillCaretDown,AiFillCaretUp } from 'react-icons/ai';
const sortCaret = (order, column)=>{
    if (!order) return (<span className='table-sort-icon'>&nbsp;&nbsp;<AiFillCaretUp/><AiFillCaretDown/></span>);
    else if (order === 'asc') return (<span className='table-sort-icon'>&nbsp;&nbsp;<AiFillCaretUp className='current-order'/><AiFillCaretDown /></span>);
    else if (order === 'desc') return (<span className='table-sort-icon'>&nbsp;&nbsp;<AiFillCaretUp/><AiFillCaretDown className='current-order'/></span>);
    return null;
};

export default sortCaret;
