import React from 'react';
import { GridItem,Text } from '@chakra-ui/react';

const TabField = ({ label, value })=>{
    return (
        <GridItem>
            <Text whiteSpace={'nowrap'}>
                {label}
            </Text>
            <Text whiteSpace={'nowrap'}>
                {value}
            </Text>
        </GridItem>
    );
};

export default TabField;
