import React, { useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { selectAppData } from 'reducers/App.service';
import { selectAuth } from 'reducers/auth.slice';
import { UserContext , AuthContext } from 'contexts/user.context';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';

// Actions
import { getCurrentUser } from 'actions/userActions';

const PrivateRoute = ({ path, exact, component: Component, ...rest }) =>{
    const { accessToken } = React.useContext(AuthContext);

    return (
        <Route
            path={path}
            exact={exact}
            render={props => {
                return accessToken
                    ? (
                        <Component {...props} {...rest} />
                    )
                    : (
                        <Redirect
                            to={{
                                pathname: '/auth/sign-in',
                                state: { from: props.location }
                            }}
                        />
                    );
            }}
        />
    );
};

const PublicRoute = ({ path, exact, component: Component, ...rest }) => {
    const { accessToken } = React.useContext(AuthContext);

    return (
        <Route
            path={path}
            exact={exact}
            render={props => {
                return !accessToken
                    ? (
                        <Component {...props} {...rest} />
                    )
                    : (
                        <Redirect
                            to={{
                                pathname: '/organization/dashboard',
                                state: { from: props.location }
                            }}
                        />
                    );
            }}
        />
    );
};

export default function App() {
    const dispatch = useDispatch();
    const context = useSelector(selectAppData);
    const authcontext = useSelector(selectAuth);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(getCurrentUser());
        }
    }, []);

    return (
        <React.StrictMode>
            <ThemeEditorProvider>
                <AuthContext.Provider value={authcontext}>
                    <UserContext.Provider value={context}>
                        <Router>
                            <Switch>
                                <PublicRoute path={'/auth'} component={AuthLayout} />
                                <PrivateRoute path={'/organization'} component={AdminLayout} />
                                <Redirect from="/" to="/organization" />
                            </Switch>
                        </Router>
                    </UserContext.Provider>
                </AuthContext.Provider>
            </ThemeEditorProvider>
        </React.StrictMode>
    );
}
