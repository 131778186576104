import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getLocalAccessToken } from 'utils/token';
import { setNavLoader } from 'reducers/App.service';
import { successToast,errorToast } from 'constants/index';
export const baseQuerySetup = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    mode: 'cors',
    prepareHeaders: (header) => {
        const token = getLocalAccessToken();
        if (token) {
            header.set('Authorization', `Bearer ${token}`);
            header.set('Access-Control-Allow-Origin', '*');
        }
        return header;
    }
});
export const openApiBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    mode: 'cors',
    prepareHeaders: (header) => {
        header.set('Access-Control-Allow-Origin', '*');
        return header;
    }
});
export const transformResponseData = (response) => {
    if (response?.statusCode === 200 || response?.statusCode === 201) {
        successToast(response.result?.message);
        return response.result;
    }
    return response;
};
export const transformErrorResponse = (errorResponse)=>{
    errorToast(errorResponse.data?.error?.message || 'Something went wrong. Please try again later');
};
export const getResponsedata = (response) => {
    if (response?.statusCode === 200 || response?.statusCode === 201) {
        return response.result.data;
    }
    return response;
};

export const getResponseResult = (response) => {
    if (response?.statusCode === 200 || response?.statusCode === 201) {
        return response.result;
    }
    return response;
};

export async function onQueryStarted(args, { dispatch , queryFulfilled, getState }) {
    try {
        dispatch(setNavLoader(true));
        const data = await queryFulfilled;
        if (data) {
            dispatch(setNavLoader(false));
        }
    } catch (error) {
        dispatch(setNavLoader(false));
    }
}
export async function onCacheEntryAdded(args, { dispatch , cacheDataLoaded }) {
    try {
        const { data } = await cacheDataLoaded;
        if (data) {
            dispatch(setNavLoader(false));
        } else {
            dispatch(setNavLoader(false));
        }
    } catch (error) {
        dispatch(setNavLoader(false));
    }
}
export const transformResponse = (response) => {
    if (response?.statusCode === 200 || response?.statusCode === 201) {
        successToast(response.result?.message);
    }
    return response.result.data;
};
