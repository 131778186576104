let intialState = {
    orgUsers: [],
    editableOrgUser: {},
    toDeleteOrgUserId: '',
    reducerState: 'IDLE'
};

function orgUserReducer(state = intialState, action) {
    switch (action.type) {
    case 'UPDATE_ORG_USER_STATE':
        return { ...state, reducerState: action.state };
    case 'FETCH_ORG_USERS':
        return { ...state, orgUsers: action.data };
    case 'EDIT_ORG_USER':
        return { ...state, editableOrgUser: action.data };
    case 'DELETED_ORG_USER':
        return { ...state, toDeleteOrgUserId: action.id };
    case 'REMOVE_ORG_USER':
        return {
            ...state,
            orgUsers: state.orgUsers.filter(user => user._id !== action.id)
        };
    default:
        return state;
    }
}

export default orgUserReducer;
