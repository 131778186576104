import { getMarketplaceApplicationStatusLabel,getLoanPurposeView } from 'utils/marketplace';
import { viewDateAndTime } from 'utils/date';

export const applicationsTableHeader = [
    {
        text: 'ID',
        dataField: 'applicationId'
    },
    {
        text: 'Loan Purpose',
        dataField: 'loanPurpose',
        formatter: (cell)=>{
            return getLoanPurposeView(cell);
        }
    },
    {
        text: 'Loan Amount',
        dataField: 'loanAmount'
    },
    {
        text: 'Status',
        dataField: 'applicationStatus',
        formatter: (cell)=>{
            return getMarketplaceApplicationStatusLabel(cell);
        }
    },
    {
        text: 'Created At',
        dataField: 'createdAt',
        formatter: (cell)=>{
            return viewDateAndTime(cell);
        }
    },
    {
        text: 'Updated At',
        dataField: 'updatedAt',
        formatter: (cell)=>{
            return viewDateAndTime(cell);
        }
    }

];
