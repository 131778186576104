import { baseQuerySetup, transformResponseData, onQueryStarted, onCacheEntryAdded,transformErrorResponse } from 'config/index';
import { createApi } from '@reduxjs/toolkit/query/react';
import { successToast , ADMIN_AUTH_PREFIX } from 'constants/index';
import { setLocalAccessToken, setLocalRefreshToken } from 'utils/token';

let authReducer = 'AUTHREDUCER';
let authTag = 'authTag';

export const authApi = createApi({
    reducerPath: authReducer,
    baseQuery: baseQuerySetup,
    tagTypes: [authTag],
    endpoints: (builder) => ({
        Signin: builder.mutation({
            query: (data) => {
                return { url: ADMIN_AUTH_PREFIX + 'login', method: 'POST', body: { email: data.email , password: data.password } };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: (response) => {
                if (response?.statusCode === 200 || response?.statusCode === 201) {
                    successToast('Sign In Successful.');
                    setLocalAccessToken(response.result.data.accessToken);
                    setLocalRefreshToken(response.result.refreshToken);
                    return response?.result?.data;
                }
                return null;
            }
        }),
        GenerateAuthToken: builder.mutation({
            query: ({ ROLE ,body }) => {
                return { url: ADMIN_AUTH_PREFIX + 'genrateResetAccessToken', method: 'POST', body };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: (response) => {
                if (response?.statusCode === 200 || response?.statusCode === 201) {
                    successToast(response.result.message);
                    return response.result;
                }
                return response;
            }
        }),
        ForgotPassword: builder.mutation({
            query: ({ ROLE , body }) => {
                return { url: ADMIN_AUTH_PREFIX + 'forgot-password', method: 'POST', body };
            },
            onQueryStarted,
            onCacheEntryAdded,

            transformResponse: transformResponseData,
            transformErrorResponse
        }),
        ResetPassword: builder.mutation({
            query: ({ temptoken , body }) => {
                return {
                    url: ADMIN_AUTH_PREFIX + 'change-password',
                    method: 'POST',
                    body: { password: body.password, repeatpassword: body.repeatpassword },
                    headers: {
                        temptoken: 'Bearer ' + temptoken
                    }
                };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: transformResponseData
        }),
        ChangeMobile: builder.mutation({
            query: ({ ROLE , body }) => {
                return {
                    url: ADMIN_AUTH_PREFIX + 'updatenumber',
                    method: 'POST',
                    body
                };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: transformResponseData
        }),
        verifyEmail: builder.query({
            query: (temptoken) => {
                return {
                    url: ADMIN_AUTH_PREFIX + 'verifyemail',
                    method: 'POST',
                    headers: {
                        temptoken: 'Bearer ' + temptoken
                    }
                };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: (response) => {
                if (response.statusCode === 200) {
                    successToast('Email verified successfully. ');
                }
            }
        }),
        AcceptInvitation: builder.mutation({
            query: ({ temptoken, body }) => {
                return {
                    url: ADMIN_AUTH_PREFIX + 'acceptinvitation',
                    method: 'POST',
                    headers: {
                        temptoken: 'Bearer ' + temptoken
                    },
                    body
                };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: (response) => {
                if (response.statusCode === 200) {
                    successToast('Invitation accepted successfully.');
                }
            }
        }),
        checkMobileNumberAvaialble: builder.mutation({
            query: ({ ROLE , body }) => {
                return { url: ADMIN_AUTH_PREFIX + 'checknumberavailability', method: 'POST', body };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: (response) => {
                if (response.statusCode === 200) {
                    return response.result.data;
                }
            }
        }),
        VerifyInvitationLink: builder.mutation({
            query: (temptoken) => {
                return {
                    url: ADMIN_AUTH_PREFIX + 'verifyinvitation',
                    method: 'GET',
                    headers: {
                        temptoken: 'Bearer ' + temptoken
                    }
                };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: (response) => {
                if (response.statusCode === 200) {
                    return response.result.data;
                }
            }
        })
    })
});

export const { useSigninMutation, useSignupMutation, useForgotPasswordMutation, useResetPasswordMutation, useGenerateAuthTokenMutation, useVerifyEmailQuery, useVerifyInvitationLinkMutation, useAcceptInvitationMutation , useChangeMobileMutation, useCheckMobileNumberAvaialbleMutation } = authApi;
