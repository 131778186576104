import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DEFAULT_STATE , APP_ORIGIN , errorToast } from 'constants/index';

import axios from 'axios';
import { getLocalAccessToken, setLocalAccessToken, setLocalRefreshToken, getLocalRefreshToken, clearLocal , getRefreshTokenUrl } from 'utils/token';

// when we expire our access token and generate new access token using refresh token
export const fetchAccessToken = createAsyncThunk(
    'authToken',
    async () => {
        const validToken = getLocalRefreshToken();
        if (validToken) {
            const accessToken = getLocalAccessToken();
            const refreshToken = getLocalRefreshToken();
            const url = getRefreshTokenUrl();
            const { data } = await axios.post(url, { accessToken, refreshToken });
            return { accessToken: data.result.accessToken, refreshToken: data.result.refreshToken };
        }
        return { accessToken: null, refreshToken: null, ROLE: null };
    }
);

// Initital  state will containe valid access token , valid refresh token
export const authSlice = createSlice({
    name: 'auth',
    initialState: AUTH_DEFAULT_STATE,
    reducers: {
        setTokens: (state, action) => {
            return { ...state, ...action.payload };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccessToken.fulfilled, (state, action) => {
                setLocalAccessToken(action.payload.accessToken);
                setLocalRefreshToken(action.payload.refreshToken);
                state.accessToken = action.payload.accessToken;
                state.refreshToken = action.payload.refreshToken;
                errorToast('Request failed try again.');
            }).addCase(fetchAccessToken.rejected, (state, action) => {
                errorToast('Session expired. Please Login.');
                window.location.replace(APP_ORIGIN);
                clearLocal();
                state.accessToken = 'failed';
                state.refreshToken = null;
            });
    }
});

export const selectAuth = (state) => state.auth;
export const { setTokens } = authSlice.actions;
