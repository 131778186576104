import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'hooks/index';
import { Formik, Field, ErrorMessage } from 'formik';
import { SignInValidation } from 'validations/auth.validation';
import { signInDefaults } from 'constants/auth.defaults';

// Chakra imports
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue
} from '@chakra-ui/react';

// Custom components
import DefaultAuth from 'layouts/auth/Default';
import { setTokens } from 'reducers/auth.slice';
import { useDispatch } from 'react-redux';
// Assets
import illustration from 'assets/img/app/BannerNew.jpg';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useSigninMutation } from 'reducers/auth.service';

function SignIn() {
    let query = useQuery();
    let history = useHistory();

    const [handleLogin , { isLoading }] = useSigninMutation();
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const brandStars = useColorModeValue('red.500', 'red.500');
    const dispatch = useDispatch();
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);
    const defaultEmail = decodeURIComponent(query.get('email') || '');
    const handleSubmit = (values)=>{
        handleLogin(values).unwrap().then((data)=>{
            dispatch(setTokens(data));
            history.push('/organization/dashboard');
        });
    };
    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: '100%', md: 'max-content' }}
                w="100%"
                mx={{ base: 'auto', lg: '0px' }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: '30px', md: '60px' }}
                px={{ base: '25px', md: '0px' }}
                mt={{ base: '40px', md: '5vh' }}
                flexDirection="column"
            >
                <>
                    <Box me="auto">
                        <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
                        </Heading>
                        <Text
                            mb="36px"
                            ms="4px"
                            color={textColorSecondary}
                            fontWeight="400"
                            fontSize="md"
                        >
            Enter your email and password to sign in!
                        </Text>
                    </Box>
                    <Flex
                        zIndex="2"
                        direction="column"
                        w={{ base: '100%', md: '420px' }}
                        maxW="100%"
                        background="transparent"
                        borderRadius="15px"
                        mx={{ base: 'auto', lg: 'unset' }}
                        me="auto"
                        mb={{ base: '20px', md: 'auto' }}
                    >
                        <Formik
                            onSubmit={handleSubmit}
                            initialValues={signInDefaults}
                            validationSchema={SignInValidation}
                        >
                            {props => (
                                <form onSubmit={props.handleSubmit}>
                                    <FormControl key={'login-form'}>
                                        <FormLabel
                                            display="flex"
                                            ms="4px"
                                            fontSize="sm"
                                            fontWeight="500"
                                            color={textColor}
                                            mb="8px"
                                        >
                      Email <Text color={brandStars}>&nbsp;*</Text>
                                        </FormLabel>
                                        <Field
                                            as={Input}
                                            isRequired={true}
                                            variant="auth"
                                            fontSize="sm"
                                            ms={{ base: '0px', md: '0px' }}
                                            type="email"
                                            name="email"
                                            placeholder="myidfi"
                                            mb="12px"
                                            fontWeight="500"
                                            size="lg"
                                            readOnly={!!defaultEmail}
                                        />
                                        <Text
                                            mb="12px"
                                            ml="12px"
                                            fontSize="sm"
                                            fontWeight="500"
                                            color="red.500"
                                        >
                                            <ErrorMessage name="username" />
                                        </Text>
                                        <FormLabel
                                            ms="4px"
                                            fontSize="sm"
                                            fontWeight="500"
                                            color={textColor}
                                            display="flex"
                                        >
                      Password <Text color={brandStars}>&nbsp;*</Text>
                                        </FormLabel>
                                        <InputGroup size="md">
                                            <Field
                                                as={Input}
                                                isRequired={true}
                                                placeholder="Min. 8 characters"
                                                name="password"
                                                fontSize="sm"
                                                mb="12px"
                                                size="lg"
                                                type={show ? 'text' : 'password'}
                                                variant="auth"
                                            />
                                            <InputRightElement
                                                display="flex"
                                                alignItems="center"
                                                mt="4px"
                                            >
                                                <Icon
                                                    color={textColorSecondary}
                                                    _hover={{ cursor: 'pointer' }}
                                                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                    onClick={handleClick}
                                                />
                                            </InputRightElement>
                                        </InputGroup>
                                        <Text
                                            mb="12px"
                                            ml="12px"
                                            fontSize="sm"
                                            fontWeight="500"
                                            color="red.500"
                                        >
                                            <ErrorMessage name="password" />
                                        </Text>
                                        {/* <Flex
                                            justifyContent="space-between"
                                            align="center"
                                            mb="24px"
                                        >
                                            <NavLink to="/auth/forgot-password">
                                                <Text
                                                    color={textColor}
                                                    fontSize="sm"
                                                    w="124px"
                                                    fontWeight="500"
                                                >
                          Forgot password ?
                                                </Text>
                                            </NavLink>
                                        </Flex> */}

                                        <Button
                                            fontSize="sm"
                                            variant="brand"
                                            fontWeight="500"
                                            w="100%"
                                            h="50"
                                            mb="24px"
                                            type="submit"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Signing In...' : 'Sign In'}
                                        </Button>
                                    </FormControl>
                                </form>
                            )}
                        </Formik>
                    </Flex>
                </>
            </Flex>
        </DefaultAuth>
    );
}

export default SignIn;
