// Chakra imports
import { Box, Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import Footer from 'components/footer/FooterAuth';
// Custom components
import { NavLink } from 'react-router-dom';

function AuthIllustration(props) {
    const { children, illustrationBackground } = props;
    // Chakra color mode
    return (
        <Flex minHeight={'100vh'} overflowY={'hidden'} position="relative" h="max-content">
            <Flex
                h={{
                    sm: '100vh',
                    md: '100vh',
                    lg: '100vh',
                    xl: '100vh'
                }}
                w="100%"

                maxW={{ md: '66%', lg: '1313px' }}
                mx="auto"
                pt={{ sm: '50px', md: '0px' }}
                px={{ lg: '20px', xl: '0px' }}
                ps={{ xl: '70px' }}
                justifyContent="start"
                direction="column"
            >
                <NavLink
                    to="/orgnization"
                    style={() => ({
                        width: 'fit-content',
                        marginTop: '40px'
                    })}
                ></NavLink>
                {children}
                <Box
                    display={{ base: 'none', md: 'block' }}
                    h="100%"
                    minH="100vh"
                    w={{ lg: '50vw', '2xl': '44vw' }}
                    position="absolute"
                    right="0px"
                >
                    <Flex
                        bg={`url(${illustrationBackground})`}
                        justify="center"
                        align="end"
                        w="100%"
                        h="100%"

                        bgSize="cover"
                        bgPosition="50%"
                        position="absolute"
                    ></Flex>
                </Box>
                <Footer />
            </Flex>
            {/* <FixedPlugin /> */}
        </Flex>
    );
}
// PROPS

AuthIllustration.propTypes = {
    illustrationBackground: PropTypes.string,
    image: PropTypes.any
};

export default AuthIllustration;
