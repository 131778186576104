import * as Yup from 'yup';
import { PASSWORD_PATTERN } from 'constants/index';

export const SignInValidation = Yup.object({
    email: Yup.string().email().required().label('Email'),
    password: Yup.string().required('Required').min(8)
});

export const ResetPasswordValidation = Yup.object({
    password: Yup.string()
        .required('Please enter password')
        .matches(PASSWORD_PATTERN,'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
    )
});
export const ForgotPasswordValidation = Yup.object({
    email: Yup.string().required('Required')
});

export const changePasswordValidation = Yup.object({
    oldPassword: Yup.string()
        .required('Please enter old password')
        .min(8, 'Old Password must be atleast 8 characters.'),
    password: Yup.string()
        .required('Please enter password')
        .matches(
            PASSWORD_PATTERN,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        ),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
    )
});
