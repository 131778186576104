import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import CustomTable from 'components/Table/CustomTable';
import { getUsersTableHeader } from 'components/Table/Headers/Users/userHeader';
import { useHistory } from 'react-router-dom';
import Card from 'components/Cards/Card';
import { useGetConsumersQuery } from 'reducers/consumer.service';

export default function OrgUser() {
    const history = useHistory();
    const { data, refetch,isLoading,isError } = useGetConsumersQuery();
    const onViewUser = (cell)=>{
        history.push('/organization/user/view-user/' + cell.id);
    };

    let userTableHeader = getUsersTableHeader({ tableActions: [{ onClick: onViewUser }], permitOnlyOneActionIndex: 1 });

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '65px' }}>

            <SimpleGrid
                mb="20px"
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: '20px', xl: '20px' }}
            >
                <Card>
                    <div className="page-head-box">
                        <p>
            Users
                        </p>
                    </div>
                    <div className="page-main-box">
                        <CustomTable data={data} handleRefresh={refetch} columns={userTableHeader} isLoading={isLoading} isError={isError} />
                    </div>
                </Card>
            </SimpleGrid>
        </Box>
    );
}
