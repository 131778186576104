import React from 'react';
/** ****************************** COMPONENTS  ********************888 */
import { TabList , Tabs, Tab, TabPanel, TabPanels } from '@chakra-ui/react';
/** ************************ HELPERS  ************************** */
import RealtorPersonal from './RealtorPersonal';
import RealtorCompany from './RealtorCompany';
export default function RealtorPreview(props) {
    const { data } = props;

    return (
        <React.Fragment>
            <Tabs>
                <TabList w={'100%'}>
                    <Tab w={'50%'}>Realtor Details</Tab>
                    <Tab w={'50%'}>Company Details </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <RealtorPersonal data={data}/>

                    </TabPanel>
                    <TabPanel>
                        <RealtorCompany {...props} data={data?.company}/>
                    </TabPanel>

                </TabPanels>
            </Tabs>

        </React.Fragment>
    );
}
