import { createStandaloneToast } from '@chakra-ui/react';
import { getLocalAccessToken, getLocalRefreshToken, getLocalROLE , getLocalUserType, getLocalFirstLogin } from 'utils/token';

const toast = createStandaloneToast();
export const APP_ORIGIN = window.location.origin.toString();
export const ADMIN_AUTH_PREFIX = '/auth/';
export const ADMIN_PREFIX = '/admin';
export const COMPANY_PREFIX = '/company';

export const MOMENT_FORMAT = 'MM/DD/YYYY';

/** *************** TYPE OF  NUMBER INPUT ************* */
export const PERCENTAGE_TYPE = 'PERCENTAGE';
export const AMOUNT_TYPE = 'AMOUNT';
export const YEARS_TYPE = 'YEARS';
export const APR_TYPE = 'APR';

// company default
export const ADDRESS_DEFAULT = {
    Address: '',
    City: '',
    State: '',
    Zipcode: '',
    Country: 'US'
};

// consumer type
export const LENDER_TYPE = 'LENDER';
export const CONSUMER_TYPE = 'CONSUMER';
export const REALTOR_TYPE = 'REALTOR';

export const CONSUMER_ROLE = 'CONSUMER';
export const LENDER_MASTER_ADMIN_ROLE = 'MASTER_ADMIN';
export const ALL = 'ALL';

export const API_URL = process.env.REACT_APP_API_URL
    ? `${process.env.REACT_APP_API_URL}/api`
    : 'https://st-orgadmin.multichannelcyber.com/api';
// export const API_URL = 'http://localhost:8000/api';

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const PASSWORD_PATTERN = /(?=^.{8,64}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&])(?!.*\s).*$/;

export const getStandardHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json'
        }
    };
};

export const getAuthenticatedHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    };
};

export const getTempAuthenticatedHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${localStorage.getItem('tempToken')}`
        }
    };
};

export const getAuthenticatedFormDataHeaders = () => {
    return {
        headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    };
};

export const successToast = message => {
    toast({
        title: message,
        status: 'success',
        position: 'top-right',

        variant: 'left-accent',
        isClosable: true

    });
};

export const errorToast = message => {
    toast({
        title: message,
        variant: 'left-accent',
        position: 'top-right',
        status: 'error',
        isClosable: true
    });
};

export const warningToast = message => {
    toast({
        title: message,
        status: 'warning',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true
    });
};

export const infoToast = message => {
    toast({
        title: message,
        status: 'info',
        isClosable: true
    });
};
export const AUTH_DEFAULT_STATE = {
    accessToken: getLocalAccessToken(),
    refreshToken: getLocalRefreshToken(),
    ROLE: getLocalROLE(),
    USERTYPE: getLocalUserType(),
    firstLogin: getLocalFirstLogin()
};

export const COMPANY_DEFAULT_STATE = {
    isCompanySetup: false
};
// app default state
export const APP_DEFAULT_STATE = {
    NavLoader: false,
    isProfileCompleted: false,
    Indecator: 0 ,
    profile: {
        profileIndecator: 0
    },
    SideBar: {
        main: '',
        sub: '',
        nav: '',
        drawer: false
    },
    SideBarType: '',
    Company: COMPANY_DEFAULT_STATE
};
