import { baseQuerySetup,getResponsedata,onQueryStarted, onCacheEntryAdded } from 'config/index';
import { createApi } from '@reduxjs/toolkit/query/react';
let consumerReducer = 'ConsumerReducer';
let consumerTag = 'ConsumerTag';

export const consumerApi = createApi({
    reducerPath: consumerReducer,
    baseQuery: baseQuerySetup,
    tagTypes: [consumerTag],
    endpoints: (builder) => ({
        getConsumers: builder.query({
            query: () => {
                return { url: '/consumers' , method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponsedata,
            providesTags: [consumerTag]
        }),
        getconsumerDetails: builder.query({
            query: (id) => {
                return { url: '/consumers/' + id , method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponsedata
        })
    })
});
export const { useGetConsumersQuery , useGetconsumerDetailsQuery } = consumerApi;
