import React from 'react';
/** ************* COMPONENTS  ******************** */
import { VStack,Tooltip,Accordion,AccordionButton,AccordionItem,AccordionIcon,AccordionPanel,Box,Heading,Flex,Button } from '@chakra-ui/react';
import NoDataCard from 'components/Cards/NoDataCard';
import LoadingDataCard from 'components/Cards/LoadingDataCard';
import ErrorCard from 'components/Cards/ErrorCard';
/** ************* HELPERS  ******************** */
import { errorToast } from 'constants/index';
import { BsDownload } from 'react-icons/bs';

const CompanyDocuments = ({ data,isLoading,isError,refetch })=>{
    const handleDownlaod = (url)=>{
        if (url) {
            const link = document.createElement('a');
            link.href = url;
            link.target = 'blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            errorToast('Unable to download file');
        }
    };
    return (
        <VStack>
            <Accordion allowToggle w={'100%'}>
                <AccordionItem defaultChecked boxShadow="base" borderRadius="md" mb={2}>
                    <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                            <Heading size='md' fontWeight={'semibold'}>
                               W-9 Form
                            </Heading>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        {
                            isLoading
                                ? <LoadingDataCard label={'Please wait while we fetch the document...'}/>
                                : isError
                                    ? <ErrorCard handleRefresh={refetch} />
                                    : data?.w9
                                        ? <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                            <iframe
                                                src={data?.w9}
                                                alt="Company Avatar"
                                                width={'100%'}
                                                height={'300px'}
                                            />
                                            <Tooltip label='Download'>
                                                <Button mt={8} onClick={()=> handleDownlaod(data?.w9)}>
                                                    <BsDownload />
                                                </Button>
                                            </Tooltip>
                                        </Flex>
                                        : <NoDataCard/>
                        }
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <Accordion allowToggle w={'100%'}>
                <AccordionItem defaultChecked boxShadow="base" borderRadius="md">
                    <AccordionButton>
                        <Box as="span" flex='1' textAlign='left'>
                            <Heading size='md' fontWeight={'semibold'}>
                               Board Resolution
                            </Heading>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        {
                            isLoading
                                ? <LoadingDataCard label={'Please wait while we fetch the document...'}/>
                                : isError
                                    ? <ErrorCard handleRefresh={refetch} />
                                    : data?.boardResulation
                                        ? <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                            <iframe
                                                src={data?.boardResulation}
                                                alt="Company Avatar"
                                                width={'100%'}
                                                height={'300px'}
                                            />
                                            <Tooltip label='Download'>
                                                <Button mt={8} onClick={()=> handleDownlaod(data?.boardResulation)}>
                                                    <BsDownload />
                                                </Button>
                                            </Tooltip>
                                        </Flex>
                                        : <NoDataCard/>
                        }
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </VStack>
    );
};

export default CompanyDocuments;
