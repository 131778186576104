import jwtdecode from 'jwt-decode';

/**
 * todo Functions  to get local values  from local storages
 * @param {*} token
 * @param {*} name
 * @returns
 */

export const getLocalAccessToken = () => {
    let token = localStorage.getItem('accessToken');
    if (token) {
        token = verifyToken(token, 'accessToken');
    }
    return token || null;
};
export const getLocalUserType = ()=>{
    return localStorage.getItem('USERTYPE');
};

export const getRefreshTokenUrl = ()=>{
    return process.env.REACT_APP_BASE_URL + '/auth/lender/refreshtoken';
};

export const getUserId = ()=>{
    const token = getLocalAccessToken();
    if (token) {
        const decodedData = decodeToken(token);
        if (decodedData) {
            if (decodedData?.cid) {
                return decodedData.cid;
            } else {
                return decodedData.lid;
            }
        } else {
            return 'UUID';
        }
    } else {
        return null || 'UUID';
    }
};

export const getLocalFirstLogin = ()=>{
    const firstLogin = localStorage.getItem('firstLogin');
    return firstLogin === 'true';
};
export const getLocalRefreshToken = () => {
    let token = localStorage.getItem('refreshToken');
    if (token) {
        token = verifyToken(token, 'refreshToken');
    }
    return token || null;
};

export const getLocalROLE = ()=>{
    return localStorage.getItem('ROLE') || '';
};

/**
 *  todo Functions verify local token
 * @param {*} token
 * @param {*} name
 * @returns
 */

export const verifyToken = (token, name) => {
    try {
        if (token) {
            const decodedToken = jwtdecode(token);
            const ExpireIn = new Date(decodedToken.exp * 1000);
            if (new Date() > ExpireIn) {
                localStorage.removeItem(name);
                return null;
            } else {
                return token;
            }
        }
        return null;
    } catch (error) {
        return null;
    }
};

/**
 * todo : Functions to set the local values
 * @param {*} token
 */
export const setLocalAccessToken = (token) => {
    localStorage.setItem('accessToken', token);
};
export const setLocalRefreshToken = (token) => {
    localStorage.setItem('refreshToken', token);
};

export const clearLocal = () => {
    localStorage.removeItem('firstLogin');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('ROLE');
};
export const setLocalROLE = (role)=>{
    localStorage.setItem('ROLE', role);
};

export const setLocalUserType = (type)=>{
    return localStorage.setItem('USERTYPE', type);
};

export const setLocalFirstLogin = (value)=>{
    localStorage.setItem('firstLogin',value);
};

/**
 *  decode the token data
 * @param {*} token
 * @returns
 */
export const decodeToken = (token) => {
    try {
        if (token) {
            const decodedToken = jwtdecode(token);
            return decodedToken;
        }
        return null;
    } catch (error) {
        return null;
    }
};
