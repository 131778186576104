import { baseQuerySetup,getResponsedata,onQueryStarted, onCacheEntryAdded } from 'config/index';
import { createApi } from '@reduxjs/toolkit/query/react';
let marketplaceReducer = 'MarketplaceReducer';
let applicationsTag = 'ApplicationsTag';
let last4weekApplicationTag = 'Last4weekApplicationTag';
let applicationStatsPerWeekTag = 'ApplicationStatsPerWeekTag';

export const applicationDashboardApi = createApi({
    reducerPath: marketplaceReducer,
    baseQuery: baseQuerySetup,
    tagTypes: [applicationsTag],
    endpoints: (builder) => ({
        getLast4weekApplication: builder.query({
            query: (status) => {
                return { url: `/marketplace/last-4-weeks-application${status ? '?status=' + status : ''}` , method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponsedata,
            providesTags: [last4weekApplicationTag]
        }),
        getApplicationStatsPerWeek: builder.query({
            query: () => {
                return { url: '/marketplace/stats' , method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponsedata,
            providesTags: [applicationStatsPerWeekTag]
        })
    })
});
export const { useGetLast4weekApplicationQuery,useGetApplicationStatsPerWeekQuery } = applicationDashboardApi;
