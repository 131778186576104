/* eslint-disable*/
import axios from 'axios';
import { API_URL, getAuthenticatedHeaders } from 'constants/index';

export const objectToArrayValues = (obj = {}) => {
    let arr = [];
    for (let el in obj) {
        if (el !== '_id') {
            arr.push(obj[el]);
        }
    }
    return arr;
};

export function generatePassword() {
    let pass = '';
    let capsStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let lowStr = 'abcdefghijklmnopqrstuvwxyz';
    let numberStr = '0123456789';
    let specialStr = '@#$';

    var char = Math.floor(Math.random() * 3);
    pass += specialStr.charAt(char);

    for (let i = 1; i <= 6; i++) {
        var char = Math.floor(Math.random() * capsStr.length + 1);
        pass += capsStr.charAt(char);
    }

    for (let i = 1; i <= 2; i++) {
        var char = Math.floor(Math.random() * lowStr.length + 1);
        pass += lowStr.charAt(char);
    }

    for (let i = 1; i <= 2; i++) {
        var char = Math.floor(Math.random() * numberStr.length + 1);
        pass += numberStr.charAt(char);
    }

    return pass;
}

export const checkIfTLDExists = async tld => {
    try {
        return axios
            .get(`${API_URL}/tld/search?name=${tld}`, getAuthenticatedHeaders())
            .then(response => {
                return true;
            })
            .catch(e => {
                return false;
            });
    } catch (e) {
        return false;
    }
};


export const getNameView =(data)=>{
    if(data){
        return `${data?.SuffixName || ''} ${data?.FirstName || ''} ${data?.LastName || ''}`.trim() || 'Name not available'
    }
    return null;
}

export const getAvatarNameView =(data)=>{
    if(data?.FirstName && data?.LastName){
        return `${data?.FirstName} ${data?.LastName}` 
    }
    return null;
}
export const getNumberView  =   (data)=>{
    if(data){
        return '+ '+ data?.ContactPointTelephonePrefix +' '+ data?.ContactPointTelephoneValue;

    }
    return null;

}

export const getBooleanView  = (value)=>{
    if(value){
         return 'Yes';
    }
    return  'No';
}


export const getGenderView  = (gender)=>{
    switch (gender) {
        case 'M':
            return 'Male';
        case 'F':
            return 'Female';
        case 'O':
            return 'Other';
        default:
            return 'None';
        }
}
export const getAddressView  = (data)=>{
    if(data && data?.Address){
        let Address  = data?.Address;
        return Address?.Address  +', '+ Address.City  +', '+ Address?.State + ', '+ Address?.Zipcode;
        
    }
    return null;
}