import { baseQuerySetup,getResponsedata,onQueryStarted, onCacheEntryAdded } from 'config/index';
import { createApi } from '@reduxjs/toolkit/query/react';
import { COMPANY_PREFIX } from 'constants/index';
let DocumentReducer = 'DocumentReducer';
let DocumentTag = 'DocumentTag';

export const documentApi = createApi({
    reducerPath: DocumentReducer,
    baseQuery: baseQuerySetup,
    tagTypes: [DocumentTag],
    endpoints: (builder) => ({
        getDocumenturl: builder.mutation({
            query: (body) => {
                return { url: COMPANY_PREFIX + '/docs-resolution' , method: 'POST', body };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponsedata
        })
    })
});
export const { useGetDocumenturlMutation } = documentApi;
