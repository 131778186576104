import { mode } from '@chakra-ui/theme-tools';
export const globalStyles = {
    colors: {
        brand: {
            100: '#b2d2e6',
            200: '#66a5ce',
            300: '#167C85',
            400: '#167C85',
            500: '#167C85',
            600: '#0F565D',
            700: '#0F565D',
            800: '#0F565D',
            900: '#003f68'
        },
        brandScheme: {
            100: '#b2d2e6',
            200: '#66a5ce',
            300: '#167C85',
            400: '#167C85',
            500: '#167C85',
            600: '#0F565D',
            700: '#0F565D',
            800: '#0F565D',
            900: '#003f68'
        },
        brandTabs: {
            100: '#b2d2e6',
            200: '#66a5ce',
            300: '#167C85',
            400: '#167C85',
            500: '#167C85',
            600: '#0F565D',
            700: '#0F565D',
            800: '#0F565D',
            900: '#003f68'
        },
        secondaryGray: {
            100: '#E0E5F2',
            200: '#E1E9F8',
            300: '#F4F7FE',
            400: '#E9EDF7',
            500: '#8F9BBA',
            600: '#A3AED0',
            700: '#707EAE',
            800: '#707EAE',
            900: '#1B2559'
        },
        red: {
            100: '#FEEFEE',
            500: '#EE5D50',
            600: '#E31A1A'
        },
        blue: {
            50: '#EFF4FB',
            500: '#167C85'
        },
        orange: {
            100: '#FFF6DA',
            500: '#FFB547'
        },
        green: {
            100: '#E6FAF5',
            500: '#01B574'
        },
        navy: {
            50: '#b2d2e6',
            100: '#b2d2e6',
            200: '#66a5ce',
            300: '#167C85',
            400: '#167C85',
            500: '#167C85',
            600: '#0F565D',
            700: '#006AAE',
            800: '#0F565D',
            900: '#003f68'
        },
        gray: {
            100: '#FAFCFE'
        }
    },
    styles: {
        global: props => ({
            body: {
                overflowX: 'hidden',
                bg: mode('secondaryGray.300', 'white')(props),
                fontFamily: 'Poppins, sans-serif' ,
                letterSpacing: '-0.5px'
            },
            input: {
                color: 'gray.700'
            },
            html: {
                fontFamily: 'DM Sans'
            }
        })
    }
};
