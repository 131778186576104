import React from 'react';
import { Avatar, Tag, Link , TagLabel,Grid , GridItem , Tabs, TabPanels , TabPanel , TabList, Tab } from '@chakra-ui/react';

import { MdEmail , MdMyLocation, MdPhone } from 'react-icons/md';
import { viewFullName } from 'utils/name';

/** ******************* STYLESHEET  *************************** */
import 'assets/css/portfolio.scss';
import { getAddressString , getStateView } from 'utils/address';
import { viewDate , viewDateAndTime } from 'utils/date';
import { viewMobile } from 'utils/number';
import ViewBranchData from './ViewBranchData';

const ViewLenderPersonal = ({ data, isLoading }) => {
    const UserAvatar = ()=>{
        return data?.avatar
            ? (<Avatar size='2xl' name={viewFullName(data)} src={data?.avatar} />)
            : (<Avatar size='2xl' name={viewFullName(data)} src={data?.avatar} />);
    };
    return isLoading
        ? <div className='center-box'>
        Loading...
        </div>
        : (
            <div className="realtor-portfolio-page">
                <div className='realtor-detail-card'>
                    <Grid templateColumns='repeat(5, 1fr)' gap={1}>
                        <GridItem w='100%' colSpan={2} >
                            <div className='avatar-box'>
                                <div className='avatar'>
                                    <UserAvatar/>
                                </div>
                                <div className="details">
                                    <br />
                                    <div className='start-box '>
                                        <MdEmail className='address-icon'/>
                                        <p className='address-text'>&nbsp;{data?.ContactPointEmailValue}</p>
                                    </div>
                                    <div className='start-box'>
                                        <MdPhone className='address-icon'/>
                                        <p className='address-text'>&nbsp;{viewMobile(data?.ContactPointTelephoneValue)}</p>
                                    </div>
                                    <div className='start-box'>
                                        <MdMyLocation className='address-icon'/>
                                        <p className='address-text'>&nbsp;{getAddressString(data?.Address)}</p>
                                    </div>
                                    <div className="start-box">
                                        Joined On&nbsp;:&nbsp;{viewDateAndTime(data?.createdAt)}
                                    </div>
                                </div>
                            </div>
                            <div className='state-licenses button-box'>
                                {
                                    data && data?.Statelicenses?.map((state) => (
                                        <Tag size={'md'} mr={1} mb={1} key={state} variant='subtle' colorScheme='cyan'>
                                            <TagLabel>{getStateView(state)}</TagLabel>
                                        </Tag>
                                    ))
                                }
                            </div>
                        </GridItem>
                        <GridItem w='100%' colSpan={3}>

                            <Tabs>
                                <TabList>
                                    <Tab>Personal Details</Tab>
                                    <Tab>Branch Details</Tab>
                                </TabList>

                                <TabPanels>
                                    <TabPanel>
                                        <div className="realtor-details">
                                            <div className='preview-box'>
                                                <div className="preview-heading space-between-box">
                Personal Details
                                                </div>
                                                <div className='outline-table'>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                NAME
                                                                </td>
                                                                <td className='capitalize-text'>
                                                                    {viewFullName(data) || 'N/A'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                NMLS
                                                                </td>
                                                                <td>
                                                                    {data?.NMLS || 'N/A'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                Branch Name
                                                                </td>
                                                                <td>
                                                                    {data?.branchId?.[0]?.branchName || 'N/A'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    On Boarding Type
                                                                </td>
                                                                <td>
                                                                    {data?.onboardingType}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    User Type
                                                                </td>
                                                                <td>
                                                                    {data?.userType }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Role
                                                                </td>
                                                                <td className='capitalize-text'>
                                                                    {data?.roleDetail?.displayName || 'N/A'}
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    Account Status
                                                                </td>
                                                                <td>
                                                                    {
                                                                        data?.accountStatus
                                                                            ? <p className='success-text'>
                                                                                ACTIVE
                                                                            </p>
                                                                            : <p className='danger-text'>
                                                                                BLOCK
                                                                            </p>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Address
                                                                </td>
                                                                <td>
                                                                    {getAddressString(data?.Address)}
                                                                </td>

                                                            </tr>
                                                            {
                                                                data?.refferedByName && <tr>
                                                                    <td>
                                                                        Referred By
                                                                    </td>
                                                                    <td>
                                                                        {data?.refferedByName}
                                                                        &nbsp;
                                                                        {
                                                                            data?.refferedBy && <>
  (
                                                                                <Link href={data?.refferedByName === 'REALTOR' ? ' /organization/realtor/view-realtor/' + data?.refferedBy : '/organization/company/stats/lender/' + data?.refferedBy} color='brand.500'>
                                                                       View Details
                                                                                </Link>
                                                                       )
                                                                            </>
                                                                        }

                                                                    </td>
                                                                </tr>
                                                            }

                                                            <tr>
                                                                <td>
                                                                    Updated On
                                                                </td>
                                                                <td>
                                                                    {viewDate(data?.updatedAt)}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <ViewBranchData data={data?.branchId?.[0]}/>

                                    </TabPanel>
                                </TabPanels>
                            </Tabs>

                        </GridItem>
                    </Grid>
                </div>
            </div>
        );
};

export default ViewLenderPersonal;
