export const approvalFiltes = [
    {
        label: 'All',
        value: ''
    },

    {
        label: 'Approval',
        value: 'APPROVAL'
    },
    {
        label: 'Editable',
        value: 'EDITABLE'
    }
];
