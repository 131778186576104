import * as Yup from 'yup';

export const CompanyActionModalScheme = Yup.object({
    message: Yup.string().optional()
});

export const InviteModalScheme = Yup.object({
    invitedToRole: Yup.string().required().label('Role'),
    emails: Yup.array().min(1,'Minimum one email required.').of(Yup.string().email()).label('Emails')
});

export const CreateOrgAdminScheme = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
    password: Yup.string()
        .required('Please enter password')
});

export const UpdateOrgAdminScheme = Yup.object({
    username: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Required')
});

export const UpdateMyOrgScheme = Yup.object({
    name: Yup.string().required('Required'),
    legalName: Yup.string().required('Required'),
    tld: Yup.string().required('Required'),
    technicalContact: Yup.string().email('Invalid email address'),
    legalContact: Yup.string().email('Invalid email address')
});

export const AddOrgUserScheme = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
    password: Yup.string()
        .required('Please enter password')

});

export const UpdateOrgUserScheme = Yup.object({
    username: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Required')
});

export const UpdateMyProfileScheme = Yup.object({
    username: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Required')
});

export const Verify2FAOtpScheme = Yup.object({
    twofa: Yup.string().min(6, 'Invalid code').required('Required')
});
