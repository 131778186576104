import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { FiRefreshCcw } from 'react-icons/fi';
export default function RefreshIcon({ handleRefresh,className }) {
    return (
        <IconButton onClick={handleRefresh} fontSize={'large'} color={'primary'} id='refresh-button' className={className}>
            <FiRefreshCcw/>
        </IconButton>
    );
}
