import { baseQuerySetup, onQueryStarted, onCacheEntryAdded, transformResponse, transformResponseData } from 'config/index';
import { successToast , ADMIN_AUTH_PREFIX } from 'constants/index';
import { createApi } from '@reduxjs/toolkit/query/react';
import { setNavLoader , setProfile } from './App.service';

let userReducer = 'userReducer';
let userTag = 'userTag';

export const userApi = createApi({
    reducerPath: userReducer,
    baseQuery: baseQuerySetup,
    tagTypes: [userTag],
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getUserProfile: builder.query({
            query: (role) => {
                return { url: ADMIN_AUTH_PREFIX + '/mydata' };
            },
            extraOptions: { maxRetries: 3 },
            onQueryStarted,
            onCacheEntryAdded: async function onCacheEntryAdded(args, { dispatch , cacheDataLoaded }) {
                try {
                    const { data } = await cacheDataLoaded;
                    dispatch(setProfile(data));
                    if (data) {
                        dispatch(setNavLoader(false));
                    } else {
                        dispatch(setNavLoader(false));
                    }
                } catch (error) {
                    dispatch(setNavLoader(false));
                }
            },
            transformResponse: (response) => {
                return response.result.data;
            },
            providesTags: [userTag]
        }),
        updateAvatar: builder.mutation({
            query: ({ ROLE , body }) => {
                return { url: ADMIN_AUTH_PREFIX + '/avtar', method: 'PUT', body };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: (response) => {
                if (response?.statusCode === 200 || response?.statusCode === 201) {
                    successToast('Profile updated successfully.');
                    return response.result;
                }
                return response;
            },
            invalidatesTags: [userTag]
        }),
        updateData: builder.mutation({
            query: ({ ROLE,body }) => {
                return { url: ADMIN_AUTH_PREFIX + '/mydata', method: 'PUT', body };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: (response) => {
                if (response?.statusCode === 200 || response?.statusCode === 201) {
                    successToast('Profile updated successfully.');
                    return response.result;
                }
                return response;
            },
            invalidatesTags: [userTag]
        }),
        changePassword: builder.mutation({
            query: ({ ROLE , body }) => {
                return { url: ADMIN_AUTH_PREFIX + 'changepassword-afterlogin', method: 'POST', body };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: (response) => {
                if (response?.statusCode === 200 || response?.statusCode === 201) {
                    successToast(response.result?.message);
                    successToast('Sign In again');
                    return response.result;
                }
                return response;
            }
        }),
        validateToken: builder.query({
            query: (ROLE) => {
                return { url: ADMIN_AUTH_PREFIX + 'validateToken', method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: (response) => {
                if (response?.statusCode === 200 || response?.statusCode === 201) {
                    return response.result.data;
                }
                return response;
            },
            providesTags: [userTag]
        }),
        SendOtp: builder.mutation({
            query: (body) => {
                return { url: '/auth/notification/sendotp/number', method: 'POST', body };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: transformResponseData

        }),
        VerifyOtp: builder.mutation({
            query: (body) => {
                return { url: '/auth/notification/verifyotp/number', method: 'POST', body };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: transformResponseData
        }),
        VerifyPhone: builder.mutation(
            {
                query: ({ ROLE , body }) => {
                    return { url: ADMIN_AUTH_PREFIX + 'verifyphone', method: 'POST', body };
                },
                onQueryStarted,
                onCacheEntryAdded,
                transformResponse: transformResponseData,

                invalidatesTags: [userTag]
            }
        ),
        Logout: builder.mutation({
            query: (ROLE) => {
                return { url: ADMIN_AUTH_PREFIX + 'logout', method: 'POST' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse
        })
    })
});

export const { useGetUserProfileQuery, useLogoutMutation, useUpdateAvatarMutation, useChangePasswordMutation, useValidateTokenQuery, useSendOtpMutation, useVerifyOtpMutation, useUpdateDataMutation, useVerifyPhoneMutation } = userApi;
export const selectUser = (state) => state.userReducer;
export const selectCompanyId = (state)=> state.userReducer.companyId;
