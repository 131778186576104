import React from 'react';

/** ***************** CHAKRA  UI COMPONENTS  ********************* */
import {
    Box,
    Icon,
    Flex,
    SimpleGrid,
    useColorModeValue,
    Link
} from '@chakra-ui/react';

/** ********************* OTHER COMPONENTS    ********************* */
import MiniStatistics from 'components/Cards/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import Filtermenu from 'components/Filters/Filtermenu';
import CustomTable from 'components/Table/CustomTable';
import Card from 'components/Cards/Card';

import { getUsersTableHeader } from 'components/Table/Headers/Users/userHeader';
import { getusersHeader , InvitedUsersHeader } from 'components/Table/Headers/Company/companyTableHeader';
import { USER_TYPES } from 'constants/roles';
import {
    MdAdminPanelSettings,
    MdSupervisedUserCircle,
    MdBusiness
    // MdOutlineCalendarMonth
} from 'react-icons/md';
import { TbBuildingSkyscraper } from 'react-icons/tb';
// import { getMarketplaceApplicationStatusLabel } from 'utils/marketplace';
/** *********************  HELPERS AND APIES   ********************* */
import { useGetDashboardstatsQuery,useGetNewUsersQuery, useGetListOfInvitationsQuery, useResentInviteMutation } from 'reducers/dashboard.service';
const ResendInvite = (props)=>{
    return (
        <Link color={'brand.500'} colorScheme='brand.500'>
            Resend
        </Link>
    );
};

const UserReports = ()=>{
    // Chakra Color Mode
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const [filter ,setfilter] = React.useState('');
    const { data: invitations , refetch: refetchInvitations, isLoading: invitationLoading } = useGetListOfInvitationsQuery(filter);

    const [resendInvite] = useResentInviteMutation();
    const { data } = useGetDashboardstatsQuery();
    const { data: recentUsers = {},refetch,isLoading,isError } = useGetNewUsersQuery();
    const onClickResend = (rowdata)=>{
        resendInvite(rowdata);
    };

    let consumerTableHeader = getUsersTableHeader();
    let lenderTableHeader = getusersHeader({ tableActions: [] });
    let inviteduserheader = InvitedUsersHeader({ tableActions: [{ label: 'Resend', icon: <ResendInvite /> ,onClick: onClickResend }] });
    return (
        <Box pt={{ base: '130px', md: '80px', xl: '65px' }}>
            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 2 ,'2xl': 4 }}
                gap="20px"
                mb="20px"
            >
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={TbBuildingSkyscraper}
                                    color={'green.500'}
                                />
                            }
                        />
                    }
                    name="Total Company Counts"
                    value={data?.companyCount || 0}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdBusiness}
                                    color={brandColor}
                                />
                            }
                        />
                    }
                    name="Total Branch Count"
                    value={data?.branchCount || 0 }
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdSupervisedUserCircle}
                                    color="purple.500"
                                />
                            }
                        />
                    }
                    name="Total Consumers"
                    value={data?.consumerTotalCount || 0 }
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdAdminPanelSettings}
                                    color="cyan.500"
                                />
                            }
                        />
                    }
                    name="Total Lender Count"
                    value={data?.lenderUserCount || 0 }
                />
            </SimpleGrid>
            <Box w='100%' mb='20px'>
                <Card>
                    <div className="space-between-box">
                        <p>Invited Users</p>
                        <Filtermenu setselected={setfilter} selected={filter} list={USER_TYPES || []} handleChange={setfilter} />
                    </div>
                    <CustomTable data={invitations} handleRefresh={refetchInvitations} columns={inviteduserheader} isLoading={invitationLoading} isError={isError} />
                </Card>
            </Box>

            <Box w='100%' mb='20px'>
                <Card>
                    <Flex className="space-between-box" mb='2'>
                        <p>
                                Recent Consumers
                        </p>
                    </Flex>
                    <CustomTable data={recentUsers?.consumers} handleRefresh={refetch} columns={consumerTableHeader} isLoading={isLoading} isError={isError} />
                </Card>
            </Box>
            <Box w='100%'>
                <Card>
                    <Flex className="space-between-box" mb='2'>
                        <p>
                                Recent Lenders
                        </p>
                    </Flex>
                    <CustomTable data={recentUsers.lenders} handleRefresh={refetch} columns={lenderTableHeader} isLoading={isLoading} isError={isError} />
                </Card>
            </Box>
        </Box>
    );
};

export default UserReports;
