import React from 'react';
import { getAddressString , getStateView } from 'utils/address';
import { viewDateAndTime } from 'utils/date';
import { viewMobile } from 'utils/number';
import { Tag, TagLabel } from '@chakra-ui/react';
export default function ViewBranchData({ data }) {
    return (

        <div className='preview-box'>
            <div className="preview-heading space-between-box">
                Branch Details
            </div>
            <div className="outline-table">

                <table>
                    <tbody>
                        <tr>
                            <td>Branch ID </td>
                            <td className='email-text' >{data?.branchId}</td>
                        </tr>
                        <tr>
                            <td>Branch Name</td>
                            <td className='email-text' >{data?.branchName || 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>NMLS</td>
                            <td > {data?.NMLS}</td>
                        </tr>
                        <tr>
                            <td>
                                Email
                            </td>
                            <td className='email-text'>
                                {data?.email || 'N/A' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 Phone
                            </td>
                            <td>
                                {viewMobile(data?.phone)}
                            </td>
                        </tr>
                        <tr>
                            <td>
Website
                            </td>
                            <td className='email-text'>
                                {data?.website || 'N/A'}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Fax
                            </td>
                            <td>
                                {data?.fax || 'N/A'}
                            </td>
                        </tr>
                        <tr>
                            <td>
                              State Licenses
                            </td>
                            <td>
                                {
                                    data && data?.Statelicenses?.map((state) => (
                                        <Tag size={'md'} mr={1} mb={1} key={state} variant='subtle' colorScheme='cyan'>
                                            <TagLabel>{state?.label || getStateView(state) || getStateView(state?.value)}</TagLabel>
                                        </Tag>
                                    ))
                                }

                            </td>
                        </tr>
                        <tr>
                            <td>
        Address
                            </td>
                            <td>
                                {getAddressString(data?.Address)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Joined myIDFi On
                            </td>
                            <td>
                                { viewDateAndTime(data?.createdAt)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                            Last Updated On

                            </td>
                            <td>
                                {viewDateAndTime(data?.updatedAt)}

                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    );
}
