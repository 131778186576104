import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from 'formik';
import { ResetPasswordValidation } from 'validations/auth.validation';
import { ResetPasswordDefaults } from 'constants/auth.defaults';
// Chakra imports
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue
} from '@chakra-ui/react';

// Custom components
import DefaultAuth from 'layouts/auth/Default';

// Assets
import illustration from 'assets/img/app/BannerNew.jpg';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

// Actions
import { useResetPasswordMutation } from 'reducers/auth.service';

function ResetPassword() {
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const [show , setShow] = React.useState(false);
    const [secondShow , setSecondShow] = React.useState(false);
    const handleClick = () => setShow(!show);
    const handleSecondClick = () => setSecondShow(!secondShow);
    const history = useHistory();
    const [handleReset , { isLoading }] = useResetPasswordMutation();
    const handleSubmit = (values)=> {
        handleReset(values).unwrap().then(()=>{
            history.push('/auth/signin');
        });
    };

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: '100%', md: 'max-content' }}
                w="100%"
                mx={{ base: 'auto', lg: '0px' }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: '30px', md: '60px' }}
                px={{ base: '25px', md: '0px' }}
                mt={{ base: '40px', md: '5vh' }}
                flexDirection="column"
            >
                <Box me="auto">
                    <Heading color={textColor} fontSize="36px" mb="10px">
            Reset Password
                    </Heading>
                    <Text
                        mb="36px"
                        ms="4px"
                        color={textColorSecondary}
                        fontWeight="400"
                        fontSize="md"
                    >
            Enter your new password.
                    </Text>
                </Box>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: '100%', md: '420px' }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: 'auto', lg: 'unset' }}
                    me="auto"
                    mb={{ base: '20px', md: 'auto' }}
                >
                    <Formik
                        initialValues={ResetPasswordDefaults}
                        validationSchema={ResetPasswordValidation}
                        onSubmit={handleSubmit}
                    >
                        {({ handleSubmit }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <FormControl key={'reset-pass-form'}>
                                        <FormLabel
                                            ms="4px"
                                            fontSize="sm"
                                            fontWeight="500"
                                            color={textColor}
                                            display="flex"
                                        >
                      Password<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size="md">
                                            <Field
                                                as={Input}
                                                isRequired={true}
                                                placeholder="Min. 8 characters"
                                                name="password"
                                                fontSize="sm"
                                                mb="12px"
                                                size="lg"
                                                type={show ? 'text' : 'password'}
                                                variant="auth"
                                            />
                                            <InputRightElement
                                                display="flex"
                                                alignItems="center"
                                                mt="4px"
                                            >
                                                <Icon
                                                    color={textColorSecondary}
                                                    _hover={{ cursor: 'pointer' }}
                                                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                    onClick={handleClick}
                                                />
                                            </InputRightElement>
                                        </InputGroup>
                                        <Text
                                            mb="12px"
                                            ml="12px"
                                            fontSize="sm"
                                            fontWeight="500"
                                            color="red.500"
                                        >
                                            <ErrorMessage name="password" />
                                        </Text>

                                        <FormLabel
                                            ms="4px"
                                            fontSize="sm"
                                            fontWeight="500"
                                            color={textColor}
                                            display="flex"
                                        >
                      Confirm Password<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size="md">
                                            <Field
                                                as={Input}
                                                isRequired={true}
                                                placeholder="Min. 8 characters"
                                                name="confirmPassword"
                                                fontSize="sm"
                                                mb="12px"
                                                size="lg"
                                                type={secondShow ? 'text' : 'password'}
                                                variant="auth"
                                            />
                                            <InputRightElement
                                                display="flex"
                                                alignItems="center"
                                                mt="4px"
                                            >
                                                <Icon
                                                    color={textColorSecondary}
                                                    _hover={{ cursor: 'pointer' }}
                                                    as={
                                                        secondShow ? RiEyeCloseLine : MdOutlineRemoveRedEye
                                                    }
                                                    onClick={handleSecondClick}
                                                />
                                            </InputRightElement>
                                        </InputGroup>
                                        <Text
                                            mb="12px"
                                            ml="12px"
                                            fontSize="sm"
                                            fontWeight="500"
                                            color="red.500"
                                        >
                                            <ErrorMessage name="confirmPassword" />
                                        </Text>
                                        <Flex
                                            justifyContent="space-between"
                                            align="center"
                                            mb="24px"
                                        >
                                            <NavLink to="/auth/sign-in">
                                                <Text
                                                    color={textColor}
                                                    fontSize="sm"
                                                    w="124px"
                                                    fontWeight="500"
                                                >
                          Back to Login ?
                                                </Text>
                                            </NavLink>
                                        </Flex>

                                        <Button
                                            fontSize="sm"
                                            variant="brand"
                                            fontWeight="500"
                                            w="100%"
                                            h="50"
                                            mb="24px"
                                            type="submit"
                                            disabled={isLoading}
                                        >
                                            {isLoading
                                                ? 'Please Wait...'
                                                : 'Reset Password'}
                                        </Button>
                                    </FormControl>
                                </form>
                            );
                        }}
                    </Formik>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default ResetPassword;
