// Chakra Imports
import {
    Avatar,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    IconButton,
    useDisclosure
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
// Custom Components
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React from 'react';
// Assets
import routes from 'routes.js';
import { FaUserPlus } from 'react-icons/fa';
// Actions
import { logout } from 'actions/userActions';
import InviteModal from 'components/Model/InviteModal';

export default function HeaderLinks(props) {
    // Chakra Color Mode
    let menuBg = useColorModeValue('white', 'navy.800');
    const inviteOthersContext = useDisclosure();
    const { onOpen } = inviteOthersContext;
    const invitOthers = ()=>{
        onOpen();
    };
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer.user);
    return (
        <Flex
            w={{ sm: '100%', md: 'auto' }}
            alignItems="center"
            flexDirection="row"
            bg={menuBg}
            flexWrap={'unset'}
            p="10px"
            borderRadius="30px"
        >
            {/* <SearchBar mb={'unset'} me="10px" borderRadius="30px" /> */}
            <SidebarResponsive routes={routes} />
            <InviteModal {...inviteOthersContext}/>
            <Menu>
                <IconButton onClick={invitOthers} p={0} pr={10} bgColor={'transparent'} _active={{ bgColor: 'transparent' }} _hover={{ bgColor: 'transparent' }}>
                    <FaUserPlus size={35} color='var(--primary-color)'/>
                </IconButton>
                <MenuButton p="0px">
                    <Avatar
                        _hover={{ cursor: 'pointer' }}
                        color="white"
                        name={user.username}
                        bg="#11047A"
                        size="sm"
                        w="40px"
                        h="40px"
                    />
                </MenuButton>
                <MenuList
                    boxShadow={shadow}
                    p="0px"
                    mt="10px"
                    borderRadius="5px"
                    bg={menuBg}
                    border="none"
                >
                    <Flex w="100%" mb="0px">
                        <Text
                            ps="20px"
                            pt="16px"
                            pb="10px"
                            w="100%"
                            borderBottom="1px solid"
                            borderColor={borderColor}
                            fontSize="sm"
                            fontWeight="700"
                            color={textColor}
                        >
              👋&nbsp; Hey, {user.username}
                        </Text>
                    </Flex>
                    <Flex flexDirection="column" p="10px">
                        <MenuItem
                            _hover={{ bg: 'none' }}
                            _focus={{ bg: 'none' }}
                            color="red.400"
                            borderRadius="8px"
                            px="14px"
                            onClick={() => dispatch(logout())}
                        >
                            <Text fontSize="sm">Log out</Text>
                        </MenuItem>
                    </Flex>
                </MenuList>
            </Menu>
        </Flex>
    );
}

HeaderLinks.propTypes = {
    variant: PropTypes.string,
    fixed: PropTypes.bool,
    secondary: PropTypes.bool,
    onOpen: PropTypes.func
};
