import React from 'react';
import { TbZoomQuestion } from 'react-icons/tb';
import { motion } from 'framer-motion';

const NoDataCard = ({ label = 'No records found' }) => {
    const animateVariants = {
        hidden: { opacity: 0, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.5,
                ease: 'easeOut'
            }
        }
    };
    return (
        <div className='no-data-card center-box'>
            <motion.div
                className='text-center'
                variants={animateVariants}
                initial="hidden"
                animate="visible">
                <div className="center-box">
                    <TbZoomQuestion className='no-data-icon' />
                </div>
                <p className='light-text bolder-text'>{label}</p>
            </motion.div>
        </div>
    );
};

export default React.memo(NoDataCard);
