import React,{ useState } from 'react';
import { MdRunningWithErrors } from 'react-icons/md';
import { motion } from 'framer-motion';
import { Button } from '@chakra-ui/react';
import LoadingDataCard from './LoadingDataCard';

const ErrorCard = ({ label = 'Something went wrong',handleRefresh }) => {
    const [isRetrying,setIsRetrying] = useState(false);
    const animateVariants = {
        hidden: { opacity: 0, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.5,
                ease: 'easeOut'
            }
        }
    };
    const handleTryAgain = async()=>{
        setIsRetrying(true);
        await handleRefresh();
        setIsRetrying(false);
    };
    if (isRetrying) {
        return (<LoadingDataCard label='Hold on while we retry...'/>);
    } else {
        return (
            <motion.div
                className="no-data-card center-box"
                variants={animateVariants}
                initial="hidden"
                animate="visible"
            >
                <div className="text-center">
                    <div className="center-box">
                        <MdRunningWithErrors className="error-icon" />
                    </div>
                    <p className="light-text bolder-text">{label}</p>
                    {
                        handleRefresh &&
                        <div className="center-box retry-btn">
                            <Button onClick={handleTryAgain}>Try Again</Button>
                        </div>
                    }
                </div>
            </motion.div>
        );
    }
};

export default React.memo(ErrorCard);
