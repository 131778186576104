let intialState = {
    orgAdmins: [],
    editableAdmin: {},
    toDeleteAdminId: '',
    reducerState: 'IDLE'
};

function orgAdminReducer(state = intialState, action) {
    switch (action.type) {
    case 'UPDATE_ADMIN_STATE':
        return { ...state, reducerState: action.state };
    case 'FETCH_ADMINS':
        return { ...state, orgAdmins: action.data };
    case 'EDIT_ADMIN':
        return { ...state, editableAdmin: action.data };
    case 'DELETED_ADMIN':
        return { ...state, toDeleteAdminId: action.id };
    case 'REMOVE_ADMIN':
        return {
            ...state,
            orgAdmins: state.orgAdmins.filter(admin => admin._id !== action.id)
        };
    default:
        return state;
    }
}

export default orgAdminReducer;
