import React from 'react';

/** ****************************** COMPONENTS  *********************88 */
import Card from 'components/Cards/Card';
import { Box,SimpleGrid } from '@chakra-ui/react';
import RefreshIcon from 'components/icons/RefreshIcon';
import { useGetRaltorDetailsQuery } from 'reducers/realtor.service';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import RealtorPreview from './Preview/RealtorPreview';
import BackButton from 'components/Button/BackButton';

const RealtorDetails = () => {
    const { id } = useParams();
    const alldata = useGetRaltorDetailsQuery(id);
    let { refetch } = alldata;

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '65px' }}>
            <SimpleGrid
                mb="20px"
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: '20px', xl: '20px' }}
            >
                <Card>
                    <div className="page-head-box space-between-box">
                        <div className="start-box">
                            <BackButton/>
                            <p>View Realtor</p>
                        </div>
                        <div>
                            <RefreshIcon handleRefresh={refetch}/>
                        </div>
                    </div>

                    <div className="page-main-box">
                        <RealtorPreview {...alldata} />
                    </div>
                </Card>
            </SimpleGrid>
        </Box>
    );
};

export default RealtorDetails;
