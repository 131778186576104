import React from 'react';

/** *********************** COMPONENTS  ********************8 */
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import Card from 'components/Cards/Card';
import CustomTable from 'components/Table/CustomTable';
import Companyactionmodel from 'components/Model/Companyactionmodel';
import Filtermenu from 'components/Filters/Filtermenu';

/** *********************** HELPERS  **********************8 */
import { approvalFiltes } from 'constants/Select-constants/filters';
import { getCompanyTableHeader } from 'components/Table/Headers/Company/companyTableHeader';
import { useGetApprovalsQuery } from 'reducers/company.service';
import { APPROVE_ACTION , REJECTE_ACTION } from 'constants/admin-actions';

const ApproveButton = ()=>{
    return (
        <Text color={'green'} colorScheme='brand'>
            Approve
        </Text>
    );
};
const RejectButton = ()=>{
    return (
        <Text colorScheme='red' color='red'>
            Reject
        </Text>
    );
};

const OrgCompany = ()=>{
    const companymodelUtil = useDisclosure();
    const { onOpen } = companymodelUtil;
    const [selectValue , setselectValue] = React.useState('');
    const { data , refetch, isLoading, isError } = useGetApprovalsQuery(selectValue);
    const [row , setrow] = React.useState(null);
    const [Action , setAction] = React.useState(APPROVE_ACTION);

    const handleSelect = (value)=>{
        setselectValue(value);
    };
    const handleAction = (ACTION , rowdata)=>{
        setAction(ACTION);
        setrow(rowdata);
        onOpen();
    };
    const onApproveCompany = (rowdata)=>{
        handleAction(APPROVE_ACTION, rowdata);
    };
    const onRejectCompany = (rowdata)=>{
        handleAction(REJECTE_ACTION , rowdata);
    };
    const companyTableHeader = getCompanyTableHeader({ tableActions: [{ label: 'Approve', icon: <ApproveButton /> ,onClick: onApproveCompany },{ label: 'Reject', icon: <RejectButton /> ,onClick: onRejectCompany }] });
    return (
        <Box pt={{ base: '130px', md: '80px', xl: '65px' }}>
            <Companyactionmodel ACTION={Action} row={row} setrow={setrow} {...companymodelUtil} />
            <Card>
                <div className="page-head-box space-between-box">
                    <p>All Approvals</p>
                    <Filtermenu selected={selectValue} list={approvalFiltes} handleChange={handleSelect} />
                </div>
                <div className="page-main-box">
                    <CustomTable
                        handleRefresh={refetch}
                        data={data}
                        columns={companyTableHeader}
                        isLoading={isLoading}
                        isError={isError}
                        defaultSorted={[{ dataField: 'status',order: 'asc' }]}
                        key="_id"
                    />
                </div>
            </Card>
        </Box>
    );
};

export default OrgCompany;
