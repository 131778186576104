import countryCodeToFlagEmoji from 'country-code-to-flag-emoji';

let intialState = {
    dashboardData: {},
    countryCodes: []
};

function dashboardReducer(state = intialState, action) {
    switch (action.type) {
    case 'DASHBOARD_STATS':
        return { ...state, dashboardData: action.data };
    case 'COUNTRY_CODES':
        return {
            ...state,
            countryCodes: action.data.map(country => {
                country.label =
            countryCodeToFlagEmoji(country.code) +
            ' (' +
            country.dial_code +
            ')';
                country.value = country._id;
                return country;
            })
        };
    default:
        return state;
    }
}

export default dashboardReducer;
