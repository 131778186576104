import orgAdminReducer from './orgAdminReducer';
import orgUserReducer from './orgUserReducer';
import dashboardReducer from './dashboardReducer';
import organizationReducer from './organizationReducer';
import { userApi } from './user.service';
import userReducer from './userReducer';
import { documentApi } from './Document.service';
import { authSlice } from './auth.slice';
import { AppSlice } from './App.service';
import { consumerApi } from './consumer.service';
import { realtorApi } from './realtor.service';
import { authApi } from 'reducers/auth.service';
import { lenderApies } from './lender.service';
import { dashboardApi } from './dashboard.service';
import { applicationDashboardApi } from './marketplace.service';
import { companyApi } from './company.service';
const rootReducer = {
    auth: authSlice.reducer,
    AppSlice: AppSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [lenderApies.reducerPath]: lenderApies.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [applicationDashboardApi.reducerPath]: applicationDashboardApi.reducer,
    [consumerApi.reducerPath]: consumerApi.reducer,
    [realtorApi.reducerPath]: realtorApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    userReducer,
    orgAdminReducer,
    orgUserReducer,
    dashboardReducer,
    organizationReducer
};

export default rootReducer;
