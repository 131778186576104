import React from 'react';
import { NavLink } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from 'formik';
import { useForgotPasswordMutation } from 'reducers/auth.service';
import { ForgotPasswordValidation } from 'validations/auth.validation';
import { forgotDefaults } from 'constants/auth.defaults';
// Chakra imports
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,
    useColorModeValue
} from '@chakra-ui/react';

// Custom components
import DefaultAuth from 'layouts/auth/Default';

// Assets
import illustration from 'assets/img/app/BannerNew.jpg';

function ForgotPassword() {
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const [handleForgotPassword , { isLoading }] = useForgotPasswordMutation();
    const handleSubmit = (values)=>{
        handleForgotPassword(values).unwrap().then(()=>{
        });
    };

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: '100%', md: 'max-content' }}
                w="100%"
                mx={{ base: 'auto', lg: '0px' }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: '30px', md: '60px' }}
                px={{ base: '25px', md: '0px' }}
                mt={{ base: '40px', md: '5vh' }}
                flexDirection="column"
            >
                <Box me="auto">
                    <Heading color={textColor} fontSize="36px" mb="10px">
            Forgot Password
                    </Heading>
                    <Text
                        mb="36px"
                        ms="4px"
                        color={textColorSecondary}
                        fontWeight="400"
                        fontSize="md"
                    >
            Enter your email / username.
                    </Text>
                </Box>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: '100%', md: '420px' }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: 'auto', lg: 'unset' }}
                    me="auto"
                    mb={{ base: '20px', md: 'auto' }}
                >
                    <Formik
                        initialValues={forgotDefaults}
                        validationSchema={ForgotPasswordValidation}
                        onSubmit={handleSubmit}
                    >
                        {({ handleSubmit }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <FormControl key={'forgot-pass-form'}>
                                        <FormLabel
                                            display="flex"
                                            ms="4px"
                                            fontSize="sm"
                                            fontWeight="500"
                                            color={textColor}
                                            mb="8px"
                                        >
                      Email / Username<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Field
                                            as={Input}
                                            isRequired={true}
                                            variant="auth"
                                            fontSize="sm"
                                            ms={{ base: '0px', md: '0px' }}
                                            type="username"
                                            name="email"
                                            placeholder="me@myidfi.com"
                                            mb="12px"
                                            fontWeight="500"
                                            size="lg"
                                        />
                                        <Text
                                            mb="12px"
                                            ml="12px"
                                            fontSize="sm"
                                            fontWeight="500"
                                            color="red.500"
                                        >
                                            <ErrorMessage name="username" />
                                        </Text>
                                        <Flex
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            mb="16px"
                                        >
                                            <NavLink to="/auth/sign-in">
                                                <Text
                                                    color={textColor}
                                                    fontSize="sm"
                                                    w="124px"
                                                    fontWeight="500"
                                                >
                          Back to Login ?
                                                </Text>
                                            </NavLink>
                                        </Flex>
                                        <Button
                                            fontSize="sm"
                                            variant="brand"
                                            fontWeight="500"
                                            w="100%"
                                            h="50"
                                            mb="24px"
                                            type="submit"
                                            disabled={isLoading }
                                        >
                                            {isLoading ? 'Please Wait...' : 'Submit'}
                                        </Button>
                                    </FormControl>
                                </form>
                            );
                        }}
                    </Formik>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default ForgotPassword;
