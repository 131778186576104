import { YEARS_TYPE,PERCENTAGE_TYPE , APR_TYPE } from 'constants/index';
import { isString } from 'lodash';

/**
 * todo  view amount  basically convert the number to the amount form
 * @param {} value
 * @returns
 */
export const viewAmount = (value) => {
    if (value) {
        const amount = new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'USD' }).format(value);
        return amount;
    }
    return '$0.00';
};
/**
  * todo here we are converting the  number into percentage value
  * @param {} value
  * @returns
  */
export const getPercentageView = (value) => {
    if (isString(value)) {
        value = ~~value.replace(/\D/g,'');
    }
    if (value) {
        let final = parseFloat(value).toFixed(3) + ' %';
        return final;
    }
    return '0 %';
};
export const getAprView = (value)=>{
    if (value) {
        let final = parseFloat(value).toFixed(2) + ' %';
        return final;
    }
    return '0.00 %';
};
export const getInterestView = (value)=>{
    if (value) {
        const newvalue = new Intl.NumberFormat('en', {
            minimumFractionDigits: 3
        }).format(value);
        return newvalue + '%';
    }
    return '0.00%';
};

export const getYearsView = (value)=>{
    if (value) {
        return value + ' Years';
    }
    return 'N/A';
};

export const viewNumber = (value)=>{
    const intl = new Intl.NumberFormat();
    if (value) {
        return intl.format(value);
    }
    return 0;
};

export const getMobileNumber = (data)=>{
    if (data && data?.ContactPointTelephoneValue && data?.ContactPointTelephonePrefix) {
        return data?.ContactPointTelephonePrefix + data?.ContactPointTelephoneValue;
    }
    return null;
};
export const ViewMobileNumber = (data)=>{
    if (data && data?.ContactPointTelephoneValue && data?.ContactPointTelephonePrefix) {
        return '+ ' + data?.ContactPointTelephonePrefix + ' ' + data?.ContactPointTelephoneValue;
    }
    return null;
};

export const prepareAuthMobileData = (data)=>{
    if (data && data?.ContactPointTelephoneValue && data?.ContactPointTelephonePrefix) {
        const numberValue = data?.ContactPointTelephoneValue.replace(data?.ContactPointTelephonePrefix, '');
        return { ...data , ContactPointTelephoneValue: numberValue };
    }
    return data;
};

export const getCreditTotal = (data, type)=>{
    if (data) {
        const installment = data?.installmentDebt?.[type] || 0;
        const studentDebt = data?.studentDebt?.[type] || 0;
        const total = parseInt(installment) + parseInt(studentDebt);
        return total;
    }
    return viewAmount(0);
};

export const getMortageExpenseTotal = (data, type)=>{
    if (data && data?.mortgageDebt) {
        const mortgage = data?.mortgageDebt?.[type] || 0;
        const secondDebt = data?.secondMortgageOrHeloc?.[type] || 0;
        const total = parseInt(mortgage) + parseInt(secondDebt);
        return viewAmount(total);
    }
    return viewAmount(0);
};
export const getExpenseDebtTotal = (data)=>{
    const secondmortgageMonthly = data?.secondMortgageOrHeloc?.monthlyPayment || 0;
    const mortgageMonthly = data?.mortgageDebt?.monthlyPayment || 0;
    return parseInt(secondmortgageMonthly) + parseInt(mortgageMonthly);
};
export const getTotalExpese = (data)=>{
    if (data) {
        if (data.isOnRent) {
            return data.rentAmount;
        }
        const debtexpeses = getExpenseDebtTotal(data);
        const insuranceAmount = data?.hazardInsurence || 0;
        const propertyTax = data?.propertyTax || 0;
        const hoaamount = data?.HOA || 0;
        const total = debtexpeses + parseInt(insuranceAmount) + parseInt(propertyTax) + parseInt(hoaamount);
        return total;
    }
    return 0;
};

export const viewPercentage = (value) => {
    if (value) {
        const floatValue = parseFloat(value).toFixed(3);
        return floatValue + ' %';
    }
    return '0 %';
};

export const viewFloat = (value) => {
    if (value) {
        let ans = parseFloat(value).toFixed(3);
        return ans;
    }
    return '0.0';
};

const presentPhone = (number)=>{
    let str = number + '';
    let cleaned = (str).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    let firstOne = str.substring(0, 1) === '1';
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } else if (cleaned.length === 11 && firstOne) {
        let final = cleaned.replace('1','');
        let phoneNumber = final.match(/^(\d{3})(\d{3})(\d{4})$/);
        return '(' + phoneNumber[1] + ') ' + phoneNumber[2] + '-' + phoneNumber[3];
    }
    return null;
};
export const viewMobile = (data) => {
    if (data && data.length > 0) {
        if (data?.length >= 10) {
            return presentPhone(data);
        } else {
            return data;
        }
    } else if (data && data?.ContactPointTelephoneValue && data?.ContactPointTelephoneValue.length > 0) {
        if (data?.ContactPointTelephoneValue.length >= 10) {
            return presentPhone(data?.ContactPointTelephoneValue);
        } else {
            return data?.ContactPointTelephoneValue;
        }
    }
    return null;
};

export const getMobileLink = (data) => {
    if (data && data?.ContactPointTelephoneValue && data?.ContactPointTelephonePrefix) {
        return data?.ContactPointTelephonePrefix + data?.ContactPointTelephoneValue;
    }
    return null;
};

export const viewHiddenNumber = (data)=>{
    if (data && data?.ContactPointTelephoneValue && data?.ContactPointTelephonePrefix) {
        const len = data?.ContactPointTelephoneValue.length;
        const num = data?.ContactPointTelephoneValue.slice(len - 3, len);
        return '+ ' + data?.ContactPointTelephonePrefix + ' ' + '**********' + num;
    }
    return null;
};

export const getNumberConfig = (TYPE)=>{
    switch (TYPE) {
    case YEARS_TYPE:
        return { suffix: ' Years', prefix: '', decimalsLimit: 0,maxLength: 3 , max: 100 };
    case APR_TYPE:
        return {
            suffix: ' %',
            prefix: '' ,
            decimalsLimit: 3,
            max: 100,
            decimalScale: 2
        };
    case PERCENTAGE_TYPE:
        return {
            suffix: ' %',
            prefix: '' ,
            decimalsLimit: 3,
            max: 100,
            decimalScale: 3
        };
    default:
        return { suffix: '', prefix: '$ ' , decimalsLimit: 2, max: 1000000000, intlConfig: { locale: 'en-US', currency: 'GBP' }, decimalScale: 2 };
    }
};

export const getDebtMessage = (value)=>{
    if (value > 45) {
        return true;
    }
    return false;
};

export function formatNumber(num) {
    return parseFloat(num.toFixed(2).replace(/\.?0+$/, ''));
}

export const getDTIValue = (value)=>{
    return parseFloat(value).toFixed(2);
};
export const isValidOTP = (value)=>{
    if (value && value.length && value.length === 6) {
        return true;
    }
    return false;
};
