import React from 'react';
import { Spinner } from '@chakra-ui/react';

const LoadingDataCard = ({ label = 'Please wait...' }) => {
    return (
        <div className="no-data-card center-box">
            <div className="text-center">
                <div className="center-box">
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="var(--primary-border)"
                        color="var(--primary-color)"
                        size="xl"
                    />
                </div>
                <p className="light-text bolder-text">{label}</p>
            </div>
        </div>
    );
};

export default React.memo(LoadingDataCard);
