import { LENDER_ROLES_LIST , LENDER_MASTER_ADMIN_ROLE, LENDER_COMPANY_ADMIN_ROLE } from 'constants/roles';

export const formatRoles = data=>{
    const array = [{ label: 'Select role', value: '' , disabled: true }];
    if (data) {
        const fileterarray = data.filter((row)=> row.roleName !== LENDER_MASTER_ADMIN_ROLE);

        const valuesarray = fileterarray.map(row=>{ return { label: row.displayName , value: row._id, disabled: false, name: row?.roleName }; });
        return [...array,...valuesarray];
    }
    return array;
};
export const getSimpleRoleList = (data)=>{
    const array = [{ label: 'All Users', value: '' , disabled: true }];
    if (data) {
        const fileterarray = data.filter((row)=> row.roleName !== LENDER_MASTER_ADMIN_ROLE);

        const valuesarray = fileterarray.map(row=>{ return { label: row.displayName , value: row._id, disabled: false }; });
        return [...array,...valuesarray];
    }
    return array;
};
export const getRolesDefault = (data)=>{
    if (data) {
        if (data.length && data.length > 0) {
            return data[0].value;
        }
        return '1';
    }
    return '1';
};

export const viewRole = (data)=>{
    if (data && data.role && data.role.displayName) {
        return data.role.displayName;
    } else {
        return 'N/A';
    }
};

export const getPreviousSelectedRole = ()=>{
    return localStorage.getItem('USERTYPE') || '';
};

export const setSelectedRole = (ROLE)=>{
    localStorage.setItem('USERTYPE', ROLE);
};

export const getCompanyAdminRoleId = (data)=>{
    if (data) {
        const companyrole = data.find((row)=> row.name === LENDER_COMPANY_ADMIN_ROLE);
        return companyrole?.value || null;
    }
    return null;
};

export const getLenderRoleView = (data)=>{
    if (data && data?.role && data?.role?.roleName) {
        const role = LENDER_ROLES_LIST.find((row)=> row.value === data.role.roleName);
        return role?.label || 'N/A';
    }
    return 'N/A';
};
