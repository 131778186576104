import React from 'react';
/** ************* COMPONENTS  ******************** */
import Card from 'components/Cards/Card';
import BackButton from 'components/Button/BackButton';
import RefreshIcon from 'components/icons/RefreshIcon';
import { Box,SimpleGrid } from '@chakra-ui/react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

import ViewLenderPersonal from 'components/Views/ViewLenderPersonal';
/** ******************  APIES  ************************************* */
import { useGetCompanyUserDetailsQuery } from 'reducers/company.service';
/** **************************** COMPONENT START FROM HERE  ******************* */
const ViewCompanyUser = () => {
    let { id } = useParams();
    const { data , refetch, isLoading } = useGetCompanyUserDetailsQuery(id);
    return (
        <Box pt={{ base: '130px', md: '80px', xl: '65px' }}>
            <SimpleGrid
                mb="20px"
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: '20px', xl: '20px' }}
            >
                <Card>
                    <div className="page-head-box space-between-box">
                        <div className="start-box">
                            <BackButton/>
                            <p>User Details</p>
                        </div>
                        <div>
                            <RefreshIcon handleRefresh={refetch}/>
                        </div>
                    </div>
                    <ViewLenderPersonal data={data} isLoading={isLoading}/>
                </Card>
            </SimpleGrid>
        </Box>
    );
};

export default ViewCompanyUser;
