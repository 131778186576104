import React from 'react';
import NoDataCard from 'components/Cards/NoDataCard';
import { useGetDocumenturlMutation } from 'reducers/Document.service';

export default function PreviewDocument({ doc }) {
    const [getDocumentUrl] = useGetDocumenturlMutation();
    let [url ,seturl] = React.useState(null);

    React.useEffect(()=>{
        if (doc) {
            getDocumentUrl({ url: doc }).unwrap().then((res)=>{
                seturl(res);
            }).catch(()=>{
                seturl(null);
            });
        }
    }, [doc]);
    return url
        ? (
            <div>
                <iframe src={url} frameBorder="0" width={500} height={250}></iframe>
            </div>
        )
        : (<NoDataCard/>);
}
