import React from 'react';

// Chakra imports
import { Flex, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

// Custom components
import Logo from 'assets/img/logos/logoNew.png';
import { HSeparator } from 'components/separator/Separator';

// Actions

export function SidebarBrand() {
    //   Chakra color mode

    const organzationData = useSelector(
        state => state.organizationReducer.orgProfile
    );

    const { logo = '' } = organzationData;

    return (
        <Flex align="center" direction="column">
            <Image
                src={logo || Logo}
                my="10px"
                h={logo ? '75px' : '100%'}
                w="175px"
                alt="Logo"
            />
            <HSeparator mb="20px" />
        </Flex>
    );
}

export default SidebarBrand;
