import React from 'react';

/** ****************************** COMPONENTS  *********************88 */
import Card from 'components/Cards/Card';
import { Box,SimpleGrid } from '@chakra-ui/react';
import CustomTable from 'components/Table/CustomTable';
import { useGetAllRealtorsQuery } from 'reducers/realtor.service';
import { allRealtorHeader } from 'components/Table/Headers/Company/companyTableHeader';
const RealtorHome = () => {
    const { data, refetch, isLoading,isError } = useGetAllRealtorsQuery();
    const companyTableHeader = allRealtorHeader({ tableActions: [] });

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '65px' }}>
            <SimpleGrid
                mb="20px"
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: '20px', xl: '20px' }}
            >
                <Card>
                    <div className="page-head-box">
                        <div>
                            <p>All Realtors</p>
                        </div>
                    </div>
                    <div className="page-main-box">
                        <CustomTable
                            rowStyle={{ cursor: 'pointer' }}
                            handleRefresh={refetch}
                            data={data}
                            columns={companyTableHeader}
                            isError={isError}
                            isLoading={isLoading}

                        />

                    </div>
                </Card>
            </SimpleGrid>
        </Box>
    );
};

export default RealtorHome;
