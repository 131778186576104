import React from 'react';
import { Box, useStyleConfig } from '@chakra-ui/react';

import { motion, AnimatePresence } from 'framer-motion';
function Card(props) {
    const { variant, children, ...rest } = props;
    const styles = useStyleConfig('Card', { variant });

    return (
        <Box __css={styles} {...rest}>
            <AnimatePresence mode='sync'>
                <motion.div
                    initial={{ x: 500, opacity: 0 }}
                    animate={{ x: 0, opacity: 1,transitionTimingFunction: 'ease-in-out' }}
                    exit={{ x: -300, opacity: 0 }}
                    transition={{ duration: 0.1 }}
                >
                    {children}
                </motion.div>
            </AnimatePresence>
        </Box>
    );
}

export default Card;
