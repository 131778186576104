import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './app';
import 'assets/css/App.css';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import configureStore from './redux/store';

const store = configureStore(); ;

ReactDOM.render(
    <ChakraProvider theme={theme}>
        <Provider store={store}>
            <App />
        </Provider>
    </ChakraProvider>,
    document.getElementById('root')
);
