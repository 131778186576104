import { getPreviousSelectedRole } from 'utils/roles';
import familyrelationships from './family-relations.json';
import { PURCHASE, REFINANCE , CASH_OUT_REFINANCE } from 'constants/marketplace-default';
export const EXPORT_FORMATS = [
    {
        label: 'CSV',
        value: 'csv'
    },
    {
        label: 'xml',
        value: 'xml'
    },
    {
        label: 'xls',
        value: 'xls'
    }
];

export const DEFAULT_EXPORT_FORMAT = 'json';
export const ID_TYPES = [
    {
        value: 'drivingLicence',
        label: 'Drivers License'
    },
    {
        value: 'passport',
        label: 'Passport'
    }
];
export const ID_DEFAULT = 'drivingLicence';

export const ACCOUNT_TYPES = [{
    value: 'SAVING',
    label: 'Saving Account'
},
{
    value: 'CHECKING',
    label: 'Checking Account'
},
{
    value: 'MONEY MARKET',
    label: 'Money Market Account'
},
{
    value: 'BROKERAGE',
    label: 'Brokerage Account'
},
{
    value: 'INDIVIDUAL',
    label: 'Individual Retirement Account'
},
{
    value: 'CD',
    label: 'Certificate of Deposit (CDs)'
},
{
    value: 'OTHER',
    label: 'Other'
}
];
export const ACCOUNT_DEFAULT = 'SAVING';

export const EMPLOYMENT_POSITIONS = [{
    value: 'EMPLOYEE',
    label: 'Employee'
},
{
    value: 'MANAGER',
    label: 'Manager'
},
{
    value: 'CEO',
    label: 'Chief Executive Offer'
},
{
    value: 'COO',
    label: 'Chief Operating Officer'
},
{
    value: 'CTO',
    label: 'Chief Technological Officer'
},
{
    value: 'CFO',
    label: 'Chief Financial Officer'
},
{
    value: 'CMO',
    label: 'Chief Marketing Officer'
},
{
    value: 'ACCOUNTS MANAGER',
    label: 'Accounts Manager'
},
{
    value: 'RECRUITMENT MANAGER',
    label: 'Recruitment Manager'
},
{
    value: 'TECHNOLOGY MANAGER',
    label: 'Technology Manager'
},
{
    value: 'STORE MANAGER',
    label: 'Store Manager'
},
{
    value: 'REGIONAL MANAGER',
    label: 'Regional Manager'
},
{
    value: 'FUNCTIONAL MANAGER',
    label: 'Functional Manager'
},
{
    value: 'DEPARTMENTAL MANAGER',
    label: 'Departmental Manager'
},
{
    value: 'GENERAL MANAGER',
    label: 'General Manager'
},
{
    value: 'OWNER',
    label: 'Owner'
}
];
export const POSITION_DEFAULT = 'EMPLOYEE';

// TRANSTION TYPE
export const TRANSATION_TYPES = [
    {
        label: 'Select',
        value: '',
        disabled: true
    },
    {
        label: 'Purchase',
        value: PURCHASE,
        disabled: false
    }, {
        label: 'Refinance',
        value: REFINANCE,
        disabled: false
    }, {
        label: 'Cash Out Refinance',
        value: CASH_OUT_REFINANCE,
        disabled: false
    }
];
export const TRANSATION_DEFAULT = '';

// employement data

export const LOAN_PURPOSE_DEFAULT = '';
export const LOAN_PURPOSE = [
    {
        label: 'Select',
        value: '',
        disabled: true
    },
    {
        label: 'Purchase',
        value: PURCHASE,
        disabled: false
    }, {
        label: 'Refinance',
        value: REFINANCE,
        disabled: false
    }, {
        label: 'Cash Out Refinance',
        value: CASH_OUT_REFINANCE,
        disabled: false
    }
];

export const SELF_EMPLOYED = 'SELF-EMPLOYED';
export const emptypes = [
    {
        value: 'W-2',
        label: 'W-2',
        incometype: 'SALARY'
    },
    {
        value: 'W-2-COMMISSION',
        label: 'W-2 With Commission',
        incometype: 'COMMISSION'
    },
    {
        value: SELF_EMPLOYED,
        label: 'Self Employed',
        incometype: 'OTHER'
    },
    {
        value: 'OTHER',
        label: 'Retired/Other',
        incometype: 'OTHER'
    }
];
export const EMPTYPE_DEFAULT = 'W-2';

export const incometype = [
    {
        label: 'Select',
        value: ''
    },
    {
        label: 'Salary',
        value: 'SALARY'
    },
    {
        label: 'Commission',
        value: 'COMMISSION'
    },
    {
        label: 'Other',
        value: 'OTHER'
    }
];
export const INCOME_DEFAULT = 'SALARY';

export const PRIMARY_RESIDENCE = 'PRIMARY';
export const SECONDARY_RESIDENCE = 'SECONDARY';
export const INVESTMENT_PROPERTY = 'INVESTMENT';

export const occupencyType = [
    {
        label: 'Select Occupancy',
        value: '',
        disabled: true
    },
    {
        label: 'Primary Residence',
        value: PRIMARY_RESIDENCE,
        disabled: false
    },
    {
        label: 'Secondary Residence ',
        value: SECONDARY_RESIDENCE,
        disabled: false
    },
    {
        label: 'Investment Property',
        value: INVESTMENT_PROPERTY,
        disabled: false
    }
];
export const OCCUPENCY_DEFAULT = '';
// list of suffixes
export const Suffixs = [
    {
        value: 'Mr',
        label: 'Mr'
    },
    {
        value: 'Mrs',
        label: 'Mrs'
    },
    {
        value: 'Ms',
        label: 'Ms'
    },
    {
        value: 'Miss',
        label: 'Miss'
    },
    {
        value: 'Mx',
        label: 'Mx'
    },
    {
        value: 'Dr',
        label: 'Dr'
    }
];
export const SUFFIX_DEFAULT = 'Mr';

// list of relationship
export const RelationShips = familyrelationships;

export const RELATIONSHIP_DEFAULT = '';

// roles
export const ROLES = [
    {
        label: 'Select Role',
        value: '',
        disabled: true
    },
    {
        value: 'CONSUMER',
        label: 'Consumer',
        disabled: false
    },
    {
        value: 'LENDER',
        label: 'Lender',
        disabled: false
    }
];
export const DEFAULT_ROLE = getPreviousSelectedRole();

// The months
export const months = [
    {
        label: 'Select Month',
        value: '',
        disabled: true
    },
    {
        label: 'January',
        value: 'January',
        disabled: false
    },
    {
        label: 'February',
        value: 'February',
        disabled: false
    },
    {
        label: 'March',
        value: 'March',
        disabled: false
    },
    {
        label: 'April',
        value: 'April',
        disabled: false
    },
    {
        label: 'May',
        value: 'May',
        disabled: false
    },
    {
        label: 'June',
        value: 'June',
        disabled: false
    },
    {
        label: 'July',
        value: 'July',
        disabled: false
    },
    {
        label: 'August',
        value: 'August',
        disabled: false
    },
    {
        label: 'September',
        value: 'September',
        disabled: false
    },
    {
        label: 'October',
        value: 'October',
        disabled: false
    },
    {
        label: 'November',
        value: 'November',
        disabled: false
    },
    {
        label: 'December',
        value: 'December',
        disabled: false
    }
];

export const GENDERLIST = [
    {
        label: 'Male',
        value: 'M'
    },
    {
        label: 'Female',
        value: 'F'
    },
    {
        label: 'Other',
        value: 'O'
    }
];
export const GENDER_DEFAULT = 'M';
