import React from 'react';

/** ************************ COMPONENTS  ***********************8 */
import { Button,Slide ,Modal, ModalOverlay , ModalHeader , ModalCloseButton , ModalBody , ModalFooter, ModalContent , FormControl } from '@chakra-ui/react';
/** *********************** FORM  FIELDS  **************88 */
import { Formik,Field, Form } from 'formik';
import { USER_TYPES } from 'constants/roles';
import { InviteModalScheme } from 'validations';
import SelectInput from 'components/Input/SelectInput';
/** ************************ HELPERS  **************** */
import { useInviteUsersMutation } from 'reducers/dashboard.service';

const InviteModal = ({ onClose, isOpen })=>{
    const [inviteRealtor,{ isLoading }] = useInviteUsersMutation();
    const handleInvite = (values)=>{
        inviteRealtor(values).unwrap().then(()=>{
            onClose();
        });
    };
    return (
        <Slide direction='top' in={isOpen} >
            <Modal isCentered size='xl' blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <Formik
                    initialValues={{
                        invitedToRole: '',
                        emails: []
                    }}
                    onSubmit={handleInvite}
                    enableReinitialize={true}
                    validationSchema={InviteModalScheme}
                >
                    {({ handleSubmit }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                name="user-edit-form"
                                className="full-width"
                            >
                                <ModalContent>
                                    <ModalHeader>Invite Users to myIDFi</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <FormControl w="100%">
                                            <Field
                                                isRequired={true}
                                                fontSize="sm"
                                                type="select"
                                                label={'Select user type'}
                                                options={USER_TYPES}
                                                name="invitedToRole"
                                                component={SelectInput}
                                                placeholder="Select"
                                                ms={{ base: '0px', md: '0px' }}
                                                mb="12px"
                                                isMulti={false}
                                                fontWeight="500"
                                                size="lg"
                                            />
                                        </FormControl>
                                        <FormControl w="100%" mt={4}>
                                            <Field
                                                isRequired={true}
                                                label={'Enter emails'}
                                                className="custom-select"
                                                name={'emails'}
                                                isCreatable={true}
                                                component={SelectInput}
                                                placeholder="Add  multiple emails..."
                                                isMulti={true}
                                            />
                                        </FormControl>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button mr={3} onClick={onClose}>
                            Close
                                        </Button>
                                        <Button type='submit' isLoading={isLoading} loadingText={'Sending.. '} variant='brand'>
                                          Send Invite
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Form>
                        );
                    }
                    }
                </Formik>
            </Modal>
        </Slide>
    );
};

export default React.memo(InviteModal);
