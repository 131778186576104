let intialState = {
    user: {},
    profileData: {},
    firstLoginResetPass: false,
    reducerState: 'IDLE',
    tempToken: '',
    loading: false
};

function userReducer(state = intialState, action) {
    switch (action.type) {
    case 'UPDATE_LOGIN_STATE':
        return { ...state, reducerState: action.state };
    case 'LOGIN':
        return { ...state, user: action.data };
    case 'MY_PROFILE':
        return { ...state, profileData: action.data };
    case 'SET_TEMP_TOKEN':
        return { ...state, tempToken: action.token };
    case 'UPDATE_LOADING_STATE':
        return { ...state, loading: action.state };
    case 'RESET_PASS_FIRST_LOGIN':
        return { ...state, firstLoginResetPass: action.key };
    case 'CURRENT_USER':
        return state;
    default:
        return state;
    }
}

export default userReducer;
