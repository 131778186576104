import React, { useEffect } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Chakra imports
import {
    Box,
    Flex,
    Spinner,
    Text
} from '@chakra-ui/react';

import { updateLoginState, validateTokenAndLogin } from 'actions/userActions';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ValidationUser() {
    const dispatch = useDispatch();
    const history = useHistory();
    let query = useQuery();
    const token = query.get('token');

    const loginState = useSelector(state => state.userReducer.reducerState);

    useEffect(() => {
        dispatch(validateTokenAndLogin(token, history));

        return () => dispatch(updateLoginState('IDLE'));
    }, [token]);

    return (
        <Flex
            w="100%"
            mx="auto"
            my={12}
            justifyContent="center"
            flexDirection="column"
            textAlign="center"
        >
            {loginState === 'LOADING'
                ? (
                    <>
                        <Box>
                            <Spinner color="navy.500" size="xl" m={8} />
                        </Box>
                        <Text fontSize="3xl">Please wait while we verify!</Text>
                    </>
                )
                : null}
            {loginState === 'SUCCESS'
                ? (
                    <>
                        <Text fontSize="3xl" my={12}>
            Validation Successfull. Redirecting to login in 5 secs
                        </Text>
                    </>
                )
                : null}
            {loginState === 'ERROR'
                ? (
                    <>
                        <Text fontSize="3xl" my={12}>
            Error while validating user! Please try again later or{' '}
                            <Text color={'brand.500'}>
                                <NavLink to={'/auth/sign-in'}>Login</NavLink>
                            </Text>
                        </Text>
                    </>
                )
                : null}
        </Flex>
    );
}

export default ValidationUser;
