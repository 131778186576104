import React from 'react';

/** ****************************** COMPONENTS  *********************88 */
import Card from 'components/Cards/Card';
import { Box,SimpleGrid } from '@chakra-ui/react';
import CustomTable from 'components/Table/CustomTable';

/** ****************************** HELPERS AND APIES  ********************** */
import { allcompaniesHeader } from 'components/Table/Headers/Company/companyTableHeader';
import { useGetAllCompaniesQuery } from 'reducers/company.service';

const Allcompany = () => {
    const { data, refetch, isLoading,isError } = useGetAllCompaniesQuery();
    const companyTableHeader = allcompaniesHeader({ tableActions: [] });

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '65px' }}>
            <SimpleGrid
                mb="20px"
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: '20px', xl: '20px' }}
            >
                <Card>
                    <div className="page-head-box">
                        <div>
                            <p>All Companies</p>
                        </div>
                    </div>
                    <div className="page-main-box">
                        <CustomTable rowStyle={{ cursor: 'pointer' }} handleRefresh={refetch} data={data} columns={companyTableHeader} isError={isError} isLoading={isLoading} />
                    </div>
                </Card>
            </SimpleGrid>
        </Box>
    );
};

export default Allcompany;
