import axios from 'axios';
import {
    API_URL,
    getStandardHeaders,
    getAuthenticatedHeaders,
    getTempAuthenticatedHeaders,
    successToast,
    errorToast
} from 'constants/index';
import { bindActionCreators } from 'redux';
import configureStore from 'redux/store';
const reduxStore = configureStore();

export const updateLoginState = state => {
    return {
        type: 'UPDATE_LOGIN_STATE',
        state
    };
};

export const updateLoadingState = state => {
    return {
        type: 'UPDATE_LOADING_STATE',
        state
    };
};

export const setTempToken = token => {
    return {
        type: 'SET_TEMP_TOKEN',
        token
    };
};

const loginAction = data => {
    return {
        type: 'LOGIN',
        data
    };
};

export const fetchedMyProfile = data => {
    return {
        type: 'MY_PROFILE',
        data
    };
};

export const updateResetPasswordModal = key => {
    return {
        type: 'RESET_PASS_FIRST_LOGIN',
        key
    };
};

export function logout() {
    return dispatch => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/auth/sign-in';
    };
}

export function login(data, history) {
    let { headers } = getStandardHeaders();
    let body = {
        username: data.username,
        password: data.password
    };
    return dispatch => {
        dispatch(updateLoginState('LOADING'));
        try {
            axios
                .post(`${API_URL}/auth/login`, body, {
                    headers
                })
                .then(response => {
                    const { result, temptoken } = response.data;
                    if (temptoken) {
                        // localStorage.setItem('tempToken', temptoken);
                        dispatch(setTempToken(temptoken));
                        dispatch(updateLoginState('SUCCESS'));
                    } else {
                        dispatch(updateLoginState('SUCCESS'));
                        const userData = JSON.stringify(result.user);
                        if (result.user && !result.user.passwordChangedAtFirstLogin) {
                            localStorage.setItem('tempToken', result.token);
                            dispatch(updateResetPasswordModal(true));
                            return;
                        }
                        localStorage.setItem('token', result.token);
                        successToast('Login successfull.');
                        localStorage.setItem('userValues', userData);
                        history.push('/organization/dashboard');
                        dispatch(loginAction(result.user));
                    }
                })
                .catch(e => {
                    const err = e.response?.data?.error || 'Error while login';
                    dispatch(updateLoginState('ERROR'));
                    errorToast(err);
                });
        } catch (e) {
            errorToast('Error while login.');
            dispatch(updateLoginState('ERROR'));
        }
    };
}

export function finalLogin(data, history) {
    let { headers } = getStandardHeaders();
    headers.authorization = `Bearer ${data.tempToken}`;
    let body = {
        otp: data.twofa
    };
    return dispatch => {
        dispatch(updateLoginState('LOADING'));
        try {
            axios
                .post(`${API_URL}/auth/login-two-fa`, body, {
                    headers
                })
                .then(response => {
                    const { result } = response.data;
                    dispatch(setTempToken('')); // reset
                    dispatch(updateLoginState('SUCCESS'));
                    const userData = JSON.stringify(result.user);
                    if (result.user && !result.user.passwordChangedAtFirstLogin) {
                        localStorage.setItem('tempToken', result.token);
                        dispatch(updateResetPasswordModal(true));
                        return;
                    }
                    localStorage.setItem('token', result.token);
                    successToast('Login successfull.');
                    localStorage.setItem('userValues', userData);
                    history.push('/organization/dashboard');
                    dispatch(loginAction(result.user));
                })
                .catch(e => {
                    const err = e.response?.data?.error || 'Error while login';
                    dispatch(updateLoginState('ERROR'));
                    errorToast(err);
                });
        } catch (e) {
            errorToast('Error while login.');
            dispatch(updateLoginState('ERROR'));
        }
    };
}

export function verify2FAotp(data, history) {
    return dispatch => {
        dispatch(updateLoadingState(true));
        const { twofa } = data;
        const body = {
            otp: twofa
        };

        try {
            axios
                .put(`${API_URL}/auth/complete-2fa-setup`, body, getAuthenticatedHeaders())
                .then(response => {
                    dispatch(updateLoadingState(false));
                    successToast('TwoFA Setup completed successfully');
                    history.push('/organization/dashboard');
                })
                .catch(e => {
                    const err = e.response?.data?.error || 'Error!';
                    dispatch(updateLoadingState(false));
                    errorToast(err);
                });
        } catch (e) {
            dispatch(updateLoadingState(false));
            errorToast('Error while twoFA setup');
        }
    };
}

export function initialForgotPassword(data) {
    return dispatch => {
        dispatch(updateLoginState('LOADING'));
        const { username } = data;
        const body = {
            email: username
        };

        try {
            axios
                .post(`${API_URL}/auth/forgot-password`, body, getStandardHeaders())
                .then(response => {
                    dispatch(updateLoginState('SUCCESS'));
                    successToast('Password reset link has been sent to your email');
                })
                .catch(e => {
                    const err = e.response?.data?.error || 'Error!';
                    dispatch(updateLoginState('ERROR'));
                    errorToast(err);
                });
        } catch (e) {
            dispatch(updateLoginState('ERROR'));
            errorToast('Error while login.');
        }
    };
}

export function validateTokenAndLogin(token, history) {
    return dispatch => {
        dispatch(updateLoginState('LOADING'));
        try {
            axios
                .post(
                    `${API_URL}/auth/validate-invitation?token=${token}`,
                    {},
                    getStandardHeaders()
                )
                .then(response => {
                    dispatch(updateLoginState('SUCCESS'));
                    const result = response.data.result;
                    setTimeout(() => {
                        history.push(
                            `/auth/sign-in?email=${encodeURIComponent(result.email)}`
                        );
                    }, 3000);
                    successToast('Validation Successfull!');
                })
                .catch(e => {
                    const err = e.response?.data?.error || 'Error!';
                    dispatch(updateLoginState('ERROR'));
                    errorToast(err);
                });
        } catch (e) {
            dispatch(updateLoginState('ERROR'));
            errorToast('Error while validating user.');
        }
    };
}

export function resetPassword(data, history) {
    return dispatch => {
        dispatch(updateLoginState('LOADING'));
        const { password, userId, token } = data;
        const body = {
            password
        };
        try {
            axios
                .post(
                    `${API_URL}/auth/reset-password/${userId}/${token}`,
                    body,
                    getStandardHeaders()
                )
                .then(response => {
                    dispatch(updateLoginState('SUCCESS'));
                    successToast('Password updated successfully.');
                    history.push('/auth/sign-in');
                })
                .catch(e => {
                    const err = e.response?.data?.error || 'Error!';
                    dispatch(updateLoginState('ERROR'));
                    errorToast(err);
                });
        } catch (e) {
            dispatch(updateLoginState('ERROR'));
            errorToast('Error while login.');
        }
    };
}

export function selfResetPassword(body, history) {
    return dispatch => {
        dispatch(updateLoginState('LOADING'));

        try {
            axios
                .put(`${API_URL}/auth/my-password`, body, getTempAuthenticatedHeaders())
                .then(response => {
                    dispatch(updateLoginState('SUCCESS'));
                    successToast('Password updated successfully. Kindly login again.');
                    localStorage.clear();
                    sessionStorage.clear();
                })
                .catch(e => {
                    const err = e.response?.data?.error || 'Error!';
                    dispatch(updateLoginState('ERROR'));
                    errorToast(err);
                });
        } catch (e) {
            dispatch(updateLoginState('ERROR'));
            errorToast('Error while login.');
        }
    };
}

export function updateMyPassword({ oldPassword, password }) {
    let body = {
        currentPassword: oldPassword,
        newPassword: password
    };

    return dispatch => {
        dispatch(updateLoginState('LOADING'));
        try {
            axios
                .put(`${API_URL}/auth/my-password`, body, getAuthenticatedHeaders())
                .then(response => {
                    dispatch(updateLoginState('IDLE'));
                    successToast('Password updated successfully. Please login again.');
                    setTimeout(() => {
                        dispatch(logout());
                    }, 2000);
                })
                .catch(e => {
                    const err = e.response?.data?.error || 'Error!';
                    errorToast(err);
                    dispatch(updateLoginState('IDLE'));
                });
        } catch (e) {
            dispatch(updateLoginState('IDLE'));
            errorToast('Error updating password.');
        }
    };
}

export function getMyProfile() {
    return dispatch => {
        try {
            axios
                .get(`${API_URL}/auth/my-profile`, getAuthenticatedHeaders())
                .then(response => {
                    const tempData = response.data.result;
                    dispatch(fetchedMyProfile(tempData));
                })
                .catch(e => {
                    const err = e.response?.data?.error || 'Error!';
                    errorToast(err);
                });
        } catch (e) {
            errorToast('Error updating password.');
        }
    };
}

export function updateMyProfile(data, history) {
    let body = { ...data };
    delete body.email;
    return dispatch => {
        dispatch(updateLoginState('LOADING'));
        try {
            axios
                .put(`${API_URL}/auth/my-profile`, body, getAuthenticatedHeaders())
                .then(response => {
                    const tempData = response.data.result;
                    if (tempData.Qr) {
                        successToast('Set up the 2FA');
                        // redirect to 2FA setup
                        history.push('/organization/two-fa');
                    } else {
                        successToast('Profile updated successfully.');
                    }
                    dispatch(updateLoginState('IDLE'));
                    dispatch(fetchedMyProfile(tempData));
                })
                .catch(e => {
                    const err = e.response?.data?.error || 'Error!';
                    errorToast(err);
                    dispatch(updateLoginState('IDLE'));
                });
        } catch (e) {
            errorToast('Error updating profile.');
            dispatch(updateLoginState('IDLE'));
        }
    };
}

export function getCurrentUser() {
    return dispatch => {
        const userDetails = JSON.parse(localStorage.getItem('userValues'));
        dispatch(loginAction(userDetails));
    };
}

const boundLogoutAction = bindActionCreators(logout, reduxStore.dispatch);

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response) {
            if (error.response.status === 401) {
                boundLogoutAction();
            }
        }
        return Promise.reject(error);
    }
);

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
