import { MOMENT_FORMAT } from 'constants/index';
import moment from 'moment';
import momentTizezone from 'moment-timezone';

/**
 *  todo these file will containe  all utility funcation of the  date
 * @param {} mydate
 * @returns
 */
export const formatDate = (mydate) => {
    if (mydate) {
        const date = new Date(mydate);
        return moment(date).format('YYYY-MM-DD');
    } else {
        const ob = new Date();
        return moment(ob).format('YYYY-MM-DD');
    }
};
export const defaultDate = () => {
    const ob = new Date();
    return moment(ob).format('YYYY-MM-DD');
};
export const getDeaultYear = ()=>{
    const ob = new Date();
    return moment(ob).format('YYYY');
};
export function viewDate(date) {
    if (date) {
        date = new Date(date);
        const timeZone = momentTizezone.tz.guess();
        return moment.tz(date, timeZone).format(MOMENT_FORMAT);
    }
    return '';
}

export function viewDateAndTime(date) {
    if (date) {
        date = new Date(date);
        return moment(date).format(MOMENT_FORMAT + ' HH:MM s ');
    }
    return '';
}

export function convertTimeStampToDate(timeStamp) {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    if (timeStamp) {
        const date = new Date(timeStamp);
        const formattedDate = `${date.toLocaleDateString('en-US', options)}`;
        return formattedDate;
    }
    return '';
}

export const formatBirthDate = (mydate) => {
    const date = new Date(mydate);
    return moment(date).format('LL');
};
export const viewJoinDate = (mydate) => {
    if (mydate) {
        let date = new Date(mydate);
        const timeZone = momentTizezone.tz.guess();
        return moment.tz(date, timeZone).format(MOMENT_FORMAT);
    }
    return null;
};

export const getYearsList = () => {
    let year = new Date().getFullYear();
    let arr = [];
    for (let i = 0; i < 100; i++) {
        arr.push({
            label: year,
            value: year,
            disabled: false
        });
        year = year - 1;
    }
    return arr;
};

export const getDateFromNow = (date) => {
    const mydate = new Date(date);
    return moment(mydate).fromNow(); // 14 hours ago
};

export const isExpires = (date) => {
    date = new Date(date).getTime();
    if (date) {
        const cur = new Date().getTime();
        if (date <= cur) {
            return true;
        }
        return false;
    }
    return false;
};
const getDateArray = (date)=>{
    let day = moment(date, 'YYYY/MM/DD').date();
    let month = 1 + moment(date, 'YYYY/MM/DD').month();
    let year = moment(date, 'YYYY/MM/DD').year();
    return [year , month, day];
};

export function getYearDiff(dateOne, dateTwo) {
    dateOne = moment(getDateArray(dateOne));
    dateTwo = moment(getDateArray(dateTwo));
    let diff = dateOne.diff(dateTwo, 'years', true);
    return parseFloat(Math.abs(diff));
}
export function getYearDiffAbs(dateOne, dateTwo) {
    dateOne = moment(dateOne);
    dateTwo = moment(dateTwo);
    let diff = Math.abs(dateOne.diff(dateTwo, 'years'));
    return diff;
}
export function getMonthDiffAbs(dateOne, dateTwo) {
    let diff = Math.abs(dateOne.diff(dateTwo, 'months'));
    if (diff >= 12) {
        let remainingMonths = diff % 12;
        return remainingMonths;
    } else {
        return diff;
    };
}
export function getDaysDiffAbs(dateOne, dateTwo) {
    dateOne = moment(getDateArray(dateOne));
    dateTwo = moment(getDateArray(dateTwo));
    let diff = dateTwo.diff(dateOne, 'days', false);
    return Math.abs(diff);
}

export function getCountDownDiff(dateOne,dateTwo) {
    dateTwo = moment(dateTwo);
    const timeBetween = moment.duration(dateTwo.diff(dateOne));
    return timeBetween;
}
export function getPeriodString(dateOne, dateTwo) {
    let date = moment();
    if (dateTwo) {
        return getYearDiffAbs(dateOne, dateTwo) + ' Yr ' + getMonthDiffAbs(moment(dateOne), moment(dateTwo)) + ' mos';
    }
    return getYearDiffAbs(dateOne, date) + ' Yr ' + getMonthDiffAbs(moment(dateOne), date) + ' mos';
}

export function getTotalDays(noofYears) {
    let date = new Date();
    let lastdate = moment(date).add(noofYears, 'years');
    let diff = getDaysDiffAbs(date , lastdate);
    return diff;
}
