import { configureStore , isRejectedWithValue } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { errorToast } from 'constants/index';
import monitorReducersEnhancer from './monitorReducers';
import rootReducer from 'reducers/index';
import thunk from 'redux-thunk';
import { userApi } from 'reducers/user.service';
import { companyApi } from 'reducers/company.service';
import { authApi } from 'reducers/auth.service';
import { lenderApies } from 'reducers/lender.service';
import { dashboardApi } from 'reducers/dashboard.service';
import { applicationDashboardApi } from 'reducers/marketplace.service';
import { documentApi } from 'reducers/Document.service';
import { consumerApi } from 'reducers/consumer.service';
import { realtorApi } from 'reducers/realtor.service';
import { setNavLoader } from 'reducers/App.service';
import { fetchAccessToken } from 'reducers/auth.slice';
/** *************** These middleware handling only authorizations  case where hitted api and get 401*************/

export const unauthenticatedMiddleware = ({
    dispatch
}) => (next) => (action) => {
    if (isRejectedWithValue(action) && action.payload.status === 401) {
        errorToast(action.payload?.data?.error.message);
        dispatch(fetchAccessToken());
        dispatch(setNavLoader(false));
        return;
    }
    return next(action);
};
/** ************************************* lender user access handler************** */

export const accessControlMiddleware = ({
    dispatch
}) => (next) => (action) => {
    if (isRejectedWithValue(action) && action.payload.status === 403) {
        errorToast('You dont have access to these services');
        dispatch(setNavLoader(false));
        return;
    }
    return next(action);
};

/** ******************************* These middleware handling only error messages ***************/

export const rtkQueryErrorLogger = ({ dispatch }) => (next) => (action) => {
    if (isRejectedWithValue(action) && (action.payload.status === 400 || action.payload.status === 500)) {
        errorToast(action.payload?.data?.error.message);
        dispatch(setNavLoader(false));
    }
    return next(action);
};
const middlewares = [
    thunk,
    accessControlMiddleware,
    unauthenticatedMiddleware,
    rtkQueryErrorLogger,
    userApi.middleware,
    authApi.middleware,
    companyApi.middleware,
    lenderApies.middleware,
    dashboardApi.middleware,
    applicationDashboardApi.middleware,
    consumerApi.middleware,
    realtorApi.middleware,
    documentApi.middleware
];

export default function configureAppStore(preloadedState) {
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
        preloadedState,
        enhancers: [monitorReducersEnhancer]
    });
    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('reducers', () => store.replaceReducer(rootReducer));
    }
    setupListeners(store.dispatch);

    return store;
}
