import React from 'react';
const ActionsFormatter = ({ cell, row, rowIndex, formatExtraData,permitOnlyOneActionIndex }) => {
    return (
        <div className='table-actions'>
            {
                permitOnlyOneActionIndex
                    ? (<button className='action-button' onClick={()=>formatExtraData[permitOnlyOneActionIndex - 1].onClick(row,cell,rowIndex)}>
                        {String(cell)}
                    </button>
                    )
                    : formatExtraData.map((action,index)=>(
                        <button key={index} className='action-button' onClick={()=>action.onClick(row,cell,rowIndex)}>
                            {action.icon || action.label || 'Click Here'}
                        </button>
                    ))
            }
        </div>
    );
};

export default ActionsFormatter;
