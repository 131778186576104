import React,{ useState } from 'react';
import Card from 'components/Cards/Card';
import MiniStatistics from 'components/Cards/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { applicationsTableHeader } from 'components/Table/Headers/Applications/applicationTableHeader';
import { MARKETPLACE_APPLICATION_STATUS_LIST } from 'constants/marketplace-default';
import Filtermenu from 'components/Filters/Filtermenu';
import CustomTable from 'components/Table/CustomTable';
import { viewNumber } from 'utils/number';
import {
    MdLeaderboard
} from 'react-icons/md';
import {
    BsFillHouseGearFill,
    BsFillHouseCheckFill
} from 'react-icons/bs';
import { RiAuctionFill } from 'react-icons/ri';
import { useColorModeValue , Box, SimpleGrid , Icon, Flex } from '@chakra-ui/react';
import { useGetApplicationStatsPerWeekQuery,useGetLast4weekApplicationQuery } from 'reducers/marketplace.service';
export default function Dashboard() {
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const [applicationStatus,setApplicationStatus] = useState('');
    const { data: applicationStats = {} } = useGetApplicationStatsPerWeekQuery();
    const { data: applicationData = {},isLoading: isApplicationDataLoading, isError: isApplicationDataError,refetch } = useGetLast4weekApplicationQuery(applicationStatus);
    const handleSelect = (value)=>{
        setApplicationStatus(value);
    };
    return (
        <Box pt={{ base: '130px', md: '80px', xl: '65px' }} w="100%">
            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 2 ,'2xl': 4 }}
                gap="20px"
                mb="20px"
            >
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdLeaderboard}
                                    color={'grey.700'}
                                />
                            }
                        />
                    }
                    name="Total Applications"
                    value={viewNumber(applicationStats.totalApplication)}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={BsFillHouseGearFill}
                                    color={'brand.500'}
                                />
                            }
                        />
                    }
                    name="Marketplace Applications"
                    value={viewNumber(applicationStats.totalInProgressApplication)}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={BsFillHouseCheckFill}
                                    color={'green.500'}
                                />
                            }
                        />
                    }
                    name="Funded Applications"
                    value={viewNumber(applicationStats.totalFundedApplication)}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={RiAuctionFill}
                                    color={'purple.500'}
                                />
                            }
                        />
                    }
                    name="Total Bids"
                    value={viewNumber(applicationStats.totalBids)}
                />
            </SimpleGrid>
            <Flex mb='20' gap={4}>
                <Box w='100%'>
                    <Card>
                        <div className="space-between-box">
                            <div>
                                <p>
                                Applications received in last 4 weeks
                                </p>
                            </div>
                            <div>
                                <Filtermenu selected={applicationStatus} list={MARKETPLACE_APPLICATION_STATUS_LIST} handleChange={handleSelect}/>
                            </div>
                        </div>
                        <div className="page-main-box">
                            <CustomTable
                                columns={applicationsTableHeader}
                                isLoading={isApplicationDataLoading}
                                isError={isApplicationDataError}
                                data={applicationData.applications}
                                handleRefresh={refetch}
                                rowStyle={{ cursor: 'pointer' }}
                            />
                        </div>
                    </Card>
                </Box>
            </Flex>

        </Box>
    );
}
