import React from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import NoDataCard from 'components/Cards/NoDataCard';
import LoadingDataCard from 'components/Cards/LoadingDataCard';
import ErrorCard from 'components/Cards/ErrorCard';
import { getStateView } from 'utils/address';
import { getBooleanView } from 'utils/index';
import { viewDate , viewDateAndTime } from 'utils/date';

const RealtorCompany = ({ data,isLoading,isError,refetch })=>{
    return (isLoading)
        ? (<LoadingDataCard />)
        : (isError)
            ? (<ErrorCard handleRefresh={refetch} />)
            : data
                ? (
                    <>
                        <div className='preview-box'>
                            <div className="preview-heading space-between-box">
                Company Details
                            </div>
                            <div className='outline-table '>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                Company Name
                                            </td>
                                            <td>
                                                {data?.companyName}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                Company Bio
                                            </td>
                                            <td>
                                                {data?.companytext}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                Email
                                            </td>
                                            <td>
                                                {data?.email }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                Phone Number
                                            </td>
                                            <td>
                                                {data?.phoneNumber}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                Website
                                            </td>
                                            <td>
                                                {data?.website}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='normal-text'>
                                Is the company address identical to the personal address.
                                            </td>
                                            <td className='normal-text'>
                                                {getBooleanView(data?.isAddressSameAsRealtor)}
                                            </td>
                                        </tr>
                                        {
                                            !data?.isAddressSameAsRealtor && <>
                                                <tr>
                                                    <td> Address 1</td>
                                                    <td colSpan={3}>{data?.Address1?.Address || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td> Address 2</td>
                                                    <td colSpan={3}>{data?.Address2?.Address || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>City</td>
                                                    <td>{data?.Address2?.City || 'N/A'}</td>
                                                    <td>State </td>
                                                    <td>{getStateView(data?.Address2?.State) || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Zipcode</td>
                                                    <td>{data?.Address2.Zipcode || 'N/A'}</td>
                                                    <td>
                                    Country
                                                    </td>
                                                    <td>{getStateView(data?.Address2?.Country) || 'N/A'}</td>
                                                </tr>

                                            </>
                                        }
                                        <tr>
                                            <td>
            Created On
                                            </td>
                                            <td>
                                                {viewDate(data?.createdAt)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                Updated On
                                            </td>
                                            <td>
                                                {viewDate(data?.updatedAt)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                        <div className="preview-box">
                            <div className="preview-heading space-between-box">
                    Logo
                            </div>
                            <div className='content'>
                                <Grid templateColumns='repeat(5, 1fr)' >
                                    <GridItem colSpan={3} >
                                        {
                                            data?.logo
                                                ? <img loading={'lazy'} src={data?.logo} style={{ width: '90%', height: '90%' }} alt="Logo" />
                                                : <p className='padding-box'>No Logo added</p>

                                        }

                                    </GridItem>
                                    <GridItem colSpan={1} >
                                        <p>Logo Added</p>
                                        {viewDateAndTime(data?.createdAt)}
                                    </GridItem>
                                    <GridItem colSpan={1} >
                                        <p>Last Updated On</p>
                                        {viewDateAndTime(data?.updatedAt)}
                                    </GridItem>

                                </Grid>

                            </div>
                        </div>
                    </>)
                : <NoDataCard label={'No Details Available'}/>;
};

export default RealtorCompany;
