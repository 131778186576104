import React from 'react';
import Card from 'components/Cards/Card';
import { Box,SimpleGrid } from '@chakra-ui/react';
import RefreshIcon from 'components/icons/RefreshIcon';
import BackButton from 'components/Button/BackButton';
import Previewrequest from 'components/Preview/Previewrequest';
import PreviewCompanyChanges from 'components/Preview/PreviewCompanyChanges';
import { EDITABLE } from 'constants/admin-actions';
import { useGetApprovalRequestQuery } from 'reducers/company.service';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const Viewrequest = () => {
    let { id } = useParams();
    const { data, refetch,isLoading } = useGetApprovalRequestQuery(id);

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '65px' }}>
            <SimpleGrid
                mb="20px"
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: '20px', xl: '20px' }}
            >
                <Card>
                    <div className="page-head-box space-between-box">
                        <div className="start-box">
                            <BackButton/>
                            <p>View Request</p>
                        </div>
                        <div>
                            <RefreshIcon handleRefresh={refetch}/>
                        </div>
                    </div>
                    <div className="page-main-box">
                        <Previewrequest data={data}/>
                        {
                            data?.type === EDITABLE && <PreviewCompanyChanges isLoading={isLoading} data={data?.updatedCompanyData}/>
                        }
                    </div>
                </Card>
            </SimpleGrid>
        </Box>
    );
};

export default Viewrequest;
