let intialState = {
    orgProfile: {},
    reducerState: 'IDLE'
};

function organizationReducer(state = intialState, action) {
    switch (action.type) {
    case 'ORGANIZATION_PROFILE':
        return { ...state, orgProfile: action.data };
    case 'UPDATE_ORG_STATE':
        return { ...state, reducerState: action.state };
    default:
        return state;
    }
}

export default organizationReducer;
