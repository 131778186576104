import React from 'react';
/** ************* COMPONENTS  ******************** */
import Card from 'components/Cards/Card';
import { Box,SimpleGrid , Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import CompanyDocuments from './Tabs/companyDocuments';
import CompanyDetails from './Tabs/companyDetails';
import BackButton from 'components/Button/BackButton';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import RefreshIcon from 'components/icons/RefreshIcon';

/** ******************  APIES  ************************************* */
import { useGetCompanyQueryQuery } from 'reducers/company.service';

/** **************************** COMPONENT START FROM HERE  ******************* */
const ViewCompany = () => {
    let { id } = useParams();
    const { data, refetch,isLoading,isError } = useGetCompanyQueryQuery(id);

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '65px' }}>
            <SimpleGrid
                mb="20px"
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: '20px', xl: '20px' }}
            >
                <Card>
                    <div className="page-head-box space-between-box">
                        <div className="start-box">
                            <BackButton/>
                            <p>View Company</p>
                        </div>
                        <div>
                            <RefreshIcon handleRefresh={refetch}/>
                        </div>
                    </div>
                    <Tabs>
                        <TabList w={'100%'}>
                            <Tab w={'50%'}>Company Details</Tab>
                            <Tab w={'50%'}>Documents</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <CompanyDetails data={data} isLoading={isLoading} isError={isError} refetch={refetch} />
                            </TabPanel>
                            <TabPanel>
                                <CompanyDocuments data={data?.companyDoc} isLoading={isLoading} isError={isError} refetch={refetch} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Card>
            </SimpleGrid>
        </Box>
    );
};

export default ViewCompany;
