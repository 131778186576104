import { createSlice } from '@reduxjs/toolkit';
import { APP_DEFAULT_STATE } from 'constants/index';
export const AppSlice = createSlice({
    name: 'AppSlice',
    initialState: APP_DEFAULT_STATE,
    reducers: {
        setNavLoader: (state, action) => {
            return { ...state, NavLoader: action.payload };
        },
        setSidebar: (state, action) => {
            return { ...state, SideBar: action.payload };
        },
        setSidebarType: (state, action)=>{
            return { ...state , SideBarType: action.payload };
        },
        setDrawer: (state, action) => {
            return { ...state, SideBar: { ...state.SideBar, drawer: action.payload } };
        },
        setProfile: (state , action)=>{
            if (action?.payload?.profileIndecator >= 5) {
                return { ...state , profile: action.payload, isProfileCompleted: true };
            }
            return { ...state , profile: action.payload };
        },
        updateProfile: (state, action)=>{
            return { ...state , profile: { ...state.profile , ...action.payload } };
        },
        IncrementIndicator: (state , action)=>{
            const Indecator = (parseInt(state.Indecator) + 1) || 1;
            if (Indecator >= 5) {
                return { ...state, Indecator, isProfileCompleted: true };
            } else {
                return { ...state, Indecator };
            }
        },
        setProfileStatus: (state , action)=>{
            return { ...state , isProfileCompleted: action.payload };
        },
        setIndicator: (state , action)=>{
            const Indecator = action.payload;
            return { ...state, Indecator };
        },
        setCompany: (state, action)=>{
            return { ...state , Company: action.payload };
        },
        setCompanyStatus: (state , action)=>{
            return { ...state , Company: { ...state.Company, isCompanySetup: action.payload } };
        }

    }
});

export const { setNavLoader, setSidebar, setDrawer, setSidebarType,updateProfile, setProfile , IncrementIndicator, setCompany , setIndicator , setProfileStatus , setCompanyStatus } = AppSlice.actions;

export const getNavLoader = (state) => state.AppSlice.NavLoader;
export const selectSidebar = (state) => state.AppSlice.SideBar;
export const getDrawer = (state) => state.AppSlice.SideBar.drawer;
export const getSidebarType = (state)=> state.AppSlice.SideBarType;
export const getProfileIndicator = (state)=> state.AppSlice.profile.profileIndicator;
export const getUserProfile = (state)=> state.AppSlice.profile;
export const selectAppData = (state)=> state.AppSlice;
export const selectCompany = (state)=> state.AppSlice.Company;
export const selectIndecator = (state)=> state.AppSlice.Indecator;
export const selectisProfileCompleted = (state)=> state.AppSlice.isProfileCompleted;
