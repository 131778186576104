import { baseQuerySetup,transformResponse,onQueryStarted, onCacheEntryAdded } from 'config/index';
import { createApi } from '@reduxjs/toolkit/query/react';
let realtorReducer = 'realtorReducer';
let realtorTag = 'realtorTag';

export const realtorApi = createApi({
    reducerPath: realtorReducer,
    baseQuery: baseQuerySetup,
    tagTypes: [realtorTag],
    endpoints: (builder) => ({
        getAllRealtors: builder.query({
            query: () => {
                return { url: '/realtors', method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse
        }),
        getRaltorDetails: builder.query({
            query: (id) => {
                return { url: '/realtors/' + id , method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse,
            invalidatesTags: [realtorTag]
        })
    })
});
export const { useInviteRealtorMutation,useGetAllRealtorsQuery , useGetRaltorDetailsQuery } = realtorApi;
