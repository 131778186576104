//  APPLICATION STATUS
export const ACTIVE_STATE = 'ACTIVE';
export const ACCEPTED_STATE = 'ACCEPTED';
export const PENDING_STATUS = 'PENDING';
export const CREATED_STATUS = 'CREATED';
export const INREVIEW_STATUS = 'INREVIEW';
export const BIDDING_STATUS = 'BIDDING';
export const BIDDING_CLOSE_STATUS = 'BIDDING_CLOSE';
export const INPROGRESS_STATUS = 'INPROGRESS';
export const CLOSED_STATUS = 'CLOSED';
export const FUNDED_STATUS = 'FUNDED';
export const EXPIRED_STATUS = 'EXPIRED';
export const CANCEL_STATUS = 'CANCELED';
export const PAUSE_STATUS = 'PAUSE';

// loan purpose and transation type

export const PURCHASE = 'PURCHASE';
export const REFINANCE = 'REFINANCE';
export const CASH_OUT_REFINANCE = 'CASH_OUT_REFINANCE';

export const APPLICATION_PIE_DEFAULT = [
    {
        name: PURCHASE,
        value: 0
    },
    {
        name: REFINANCE,
        value: 0
    },
    {
        name: CASH_OUT_REFINANCE,
        value: 0
    }
];

// marketplace defaults
export const MARKETPLACE_FILTER_DEFAULT = {
    noOfYears: '',
    loanPurpose: '',
    proposePercentage: '',
    creditScore: '',
    loanAmount: '',
    propertyType: ''
};
export const PIE_CHART_DEFAULT = [
    { id: 111, name: 'Principle and Interest', value: 200, amount: 88089, color: 'var(--title-color)', label: 'Principle and Interest', isEditable: false, isView: true, inputname: 'monthly.PrincipleAndInterest' },
    { id: 222, name: 'MI', value: 200, amount: 88089, color: 'var(--primary-color)', label: 'MI', isEditable: false, isView: true, inputname: 'monthly.MI' },
    { id: 333, name: 'Property Tax', value: 200, amount: 88089, color: '#00C49F', label: 'Property Tax', isEditable: false, isView: true, inputname: 'monthly.propertyTax' },
    { id: 444, name: 'Insurance', value: 200, amount: 88089, color: '#FFBB28', label: 'Insurance', isEditable: false, isView: true, inputname: 'monthly.Insurance' },
    { id: 555, name: 'HOA', value: 200, amount: 88089, color: '#0088FE', label: 'HOA', isEditable: false, isView: true, inputname: 'monthly.HOA' }
];

export const MARKETPLACE_APPLICATION_STEPS = [CREATED_STATUS, PENDING_STATUS, INREVIEW_STATUS, BIDDING_STATUS, BIDDING_CLOSE_STATUS, INPROGRESS_STATUS, FUNDED_STATUS];
export const MARKETPLACE_APPLICATION_STATUS = {
    CREATED: {
        label: 'Created',
        activestep: 1,
        success: true,
        message: {
            consumer: {
                label: 'Application scenario ready to place to marketplace.',
                sublabel: 'Please place application in marketplace.'
            },
            lender: null
        }
    },
    PENDING: {
        label: 'Pending',
        activestep: 2,
        success: true,
        message: {
            consumer: {
                label: 'Application is in pending state'

            },
            lender: null
        }
    },
    INREVIEW: {
        label: 'In Review',
        activestep: 3,
        success: true,
        message: {
            consumer: {
                label: 'Application is in review state.',
                sublabel: 'Myidfi will review your application then your application  moved to next state'
            },
            lender: null
        }
    },
    BIDDING: {
        label: 'Bidding',
        activestep: 4,
        success: true,
        message: {
            consumer: {
                label: 'Application is on marketplace.',
                sublabel: 'Lender can view your application and bid on that.'
            },
            lender: null
        }
    },
    PAUSE: {
        label: 'Pause',
        activestep: 5,
        success: false,
        message: {
            consumer: {
                label: 'Application is paused.',
                sublabel: 'Your application is visible on marketplace but lenders cannot bid.'
            },
            lender: {
                label: 'Application is paused.',
                sublabel: 'User paused application you cannot bid.'
            }
        }
    },
    BIDDING_CLOSE: {
        label: 'Bidding Close',
        activestep: 5,
        success: false,
        message: {
            consumer: {
                label: 'You have successfully accepted the bid from lender.',
                sublabel: 'Lender details as below.'
            },
            lender: {
                label: 'Bid accepted  by the consumer.',
                sublabel: 'Below are the consumer details.'
            }
        }
    },
    INPROGRESS: {
        label: 'In Progress',
        activestep: 6,
        success: true,
        message: {
            consumer: {
                label: 'Your application is in progress please contact lender.',
                sublabel: 'Lender details as below.'
            },
            lender: {
                label: 'Bid accepted  by the consumer.',
                sublabel: 'Below are the consumer details.'
            }
        }
    },
    FUNDED: {
        label: 'Funded',
        activestep: 7,
        success: true,
        message: {
            consumer: {
                label: 'Lender closed your application.',
                sublabel: 'Lender details as below.'
            },
            lender: {
                label: 'Application successfully closed.',
                sublabel: 'User details as follow.'
            }
        }
    },
    CANCELED: {
        label: 'Cancel',
        activestep: 9,
        success: false,
        message: {
            consumer: {
                label: 'Applicaton canceled successfully.',
                sublabel: 'Your application is cancelled.'
            },
            lender: {
                label: 'Consumer cancelled the application.',
                sublabel: ''
            }
        }
    },
    EXPIRED: {
        label: 'Expire',
        activestep: 10,
        success: false,
        message: {
            consumer: {
                label: 'Applicaton Expired.'
            },
            lender: {
                label: 'Application Expired.'
            }
        }
    }
};

export const MARKETPLACE_APPLICATION_STATUS_LIST = [
    { value: '', label: 'All' },
    ...Object.entries(MARKETPLACE_APPLICATION_STATUS).map(([key, value]) => {
        return {
            value: key,
            ...value
        };
    })
];
