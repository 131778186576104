import React from 'react';
import CreateSelect from 'react-select/creatable';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const selectStyles =
    {
        fontFamily: 'Poppins,sans-serif',
        padding: '0px',
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: 'lightgray',
                fontSize: 'small'
            };
        },
        control: (base, state) => ({
            ...base,
            padding: '2px',
            fontFamily: 'Poppins,sans-serif',
            border: state.isFocused ? '1px solid var(--primary-color)' : '1px solid var(--primary-border)' ,
            boxShadow: state.isFocused ? '1px solid var(--primary-color)' : '1px solid var(--primary-border)',
            '&:hover': {
                border: state.isFocused ? '1px solid var(--primary-color)' : '1px solid var(--primary-border)'

            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            let finalcolor = (isFocused || isSelected) ? 'white' : 'black';
            return {
                ...styles,
                fontFamily: 'Poppins,sans-serif',
                cursor: 'pointer',
                color: isDisabled ? 'var(--primary-border)' : finalcolor ,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: 'var(--primary-color) !important',
                    color: 'white !important'
                },
                ':focus': {
                    ...styles[':active'],
                    backgroundColor: 'var(--primary-color) !important',
                    color: 'white !important'
                }
            };
        },
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                fontSize: '1.2rem',
                borderRadius: '0.2rem',
                padding: '3px',
                color: 'var(--primary-color)',
                border: '1px solid var(--primary-border)',
                backgroundColor: 'var(--primary-light)',
                ':hover': {
                    backgroundColor: 'var(--secondary-background)',
                    color: 'var(--primary-color)'
                },
                ':focus': {
                    backgroundColor: 'var(--secondary-background) !important',
                    color: 'var(--primary-color) !important'
                },
                ':active': {
                    backgroundColor: 'var(--secondary-background) !important',
                    color: 'var(--primary-color) !important'
                }
            };
        }

    };
const getSelectStyles = (errors , name)=>{
    if (errors?.[name]?.message) {
        return {
            ...selectStyles ,
            control: (base, state) => ({
                ...base,
                padding: '2px',
                fontFamily: 'Poppins,sans-serif',
                border: '1px solid var(--primary-danger)' ,
                boxShadow: '1px solid var(--primary-danger)'

            })
        };
    }
    return selectStyles;
};
const SelectError = ({ error = '' , values = [] })=>{
    if (Array.isArray(error)) {
        return (
            <React.Fragment>
                {
                    error.map((message, index)=>(
                        <small className='danger-text' key={index}>
                            {values[index]} is Invalid email.
                            <br />
                        </small>
                    ))
                }

            </React.Fragment>
        );
    }
    return (
        <small className="danger-text">
            {error}
        </small>
    );
};

export default function SelectInput (props) {
    const {
        defaultValue,
        isMulti,
        placeholder,
        label,
        required,
        isCreatable = false,
        disabled,
        field,
        form,
        options = []
    } = props;
    const animatedComponents = makeAnimated();
    const [IsMenuOpen, setIsMenuOpen] = React.useState(false);
    let error = form.errors[field.name];
    const onChange = (option) => {
        if (!isMulti) setIsMenuOpen(false);
        form.setFieldValue(
            field.name,
            isMulti
                ? option.map((item) => item.value)
                : (option).value
        );
    };

    const getValue = () => {
        if (options) {
            return isMulti
                ? options.filter(option => field.value.indexOf(option.value) >= 0)
                : options.find(option => option.value === field.value);
        } else {
            return isMulti ? [] : ('');
        }
    };

    const onMenuOpen = () => setIsMenuOpen(true);
    const onMenuClose = () => setIsMenuOpen(false);

    return (
        <div id='select-input'>
            <label htmlFor="select-input" className='label-box'>{label} <small className='required'>{required && '*'}</small></label>
            {
                isCreatable
                    ? <CreateSelect
                        isMulti= {isMulti }
                        defaultValue={'Enter email here '}
                        aria-invalid ={'email'}
                        options={[]}
                        isClearable={isMulti}
                        isSearchable={true}
                        components={animatedComponents}
                        styles ={selectStyles}
                        onChange={onChange}
                        name={ field.name}
                        placeholder={'Type email and press enter...'}
                        noOptionsMessage={() => 'No Data Found'}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: '5px',
                            borderWidth: '1px',
                            borderColor: 'var(--primary-border)',
                            colors: {
                                ...theme.colors,
                                primary25: 'var(--primary-color)',
                                primary: 'var(--primary-color)'
                            }
                        })}

                    />
                    : <Select
                        closeMenuOnSelect={false}
                        isMulti= {isMulti }
                        defaultValue={defaultValue || ''}
                        options={options || []}
                        onChange={onChange}
                        value={getValue()}
                        isDisabled={disabled || false}
                        isClearable={isMulti}
                        isSearchable={false}
                        isOptionDisabled={(option)=> option.disabled}
                        components={animatedComponents}
                        menuIsOpen={IsMenuOpen}
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuClose}
                        styles ={getSelectStyles(field?.errors , field.name)}
                        name={ field.name}
                        placeholder={ placeholder || 'Select'}
                        noOptionsMessage={() => 'No Data Found'}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: '5px',
                            borderWidth: '1px',
                            borderColor: 'var(--primary-border)',
                            colors: {
                                ...theme.colors,
                                primary25: 'var(--primary-color)',
                                primary: 'var(--primary-color)'
                            }
                        })}
                    />
            }
            { error && <SelectError error={error} values={field.value}/>}
        </div>
    );
}
