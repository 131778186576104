import React from 'react';
import { viewDate , viewDateAndTime } from 'utils/date';
import { getAddressString } from 'utils/address';
import { Tag, Grid, GridItem, Link } from '@chakra-ui/react';
import NoDataCard from 'components/Cards/NoDataCard';
import LoadingDataCard from 'components/Cards/LoadingDataCard';
import ErrorCard from 'components/Cards/ErrorCard';
import { viewMobile } from 'utils/number';

const CompanyDetails = ({ data, isLoading, isError, refetch }) => {
    const admin = data?.companyAdminId;
    return (isLoading)
        ? (<LoadingDataCard />)
        : (isError)
            ? (<ErrorCard handleRefresh={refetch} />)
            : data
                ? (
                    <React.Fragment>
                        <Grid templateColumns='repeat(2, 1fr)' gap={2}>
                            <GridItem >

                                <div className='preview-box'>
                                    <div className="preview-heading">
                                        <p>Company Details  </p>
                                    </div>
                                    <div className='preview-body' >
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>

                                        Company ID
                                                    </td>
                                                    <td>
                                                        {data?.companyId}
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>
                                            Email
                                                    </td>
                                                    <td>
                                                        <Link href={'mailto:' + data?.email} color='brand.500'>
                                                            {data?.email}

                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                            Phone
                                                    </td>
                                                    <td>
                                                        {viewMobile(data?.phone)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                            Name

                                                    </td>
                                                    <td>
                                                        { data?.name || 'N/A'}

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                            NMLS

                                                    </td>
                                                    <td>
                                                        {data?.NMLS || 'N/A'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                            On Boarding Status
                                                    </td>
                                                    <td>
                                                        {data?.isApprovedByAdmin ? 'COMPLETED' : 'INPROGRESS'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
Formed In
                                                    </td>
                                                    <td>
                                                        {viewDate(data?.dateFormed)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
Website
                                                    </td>
                                                    <td>
                                                        {data?.website || 'N/A'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                            Fax
                                                    </td>
                                                    <td>
                                                        {data?.fax || 'N/A'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                           Physical Address
                                                    </td>
                                                    <td>
                                                        {getAddressString(data?.Address)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                        Mailing  Addresss

                                                    </td>
                                                    <td>
                                                        {
                                                            data?.isMailingAddressEqualPhysical
                                                                ? 'Mailing and physical address are same'
                                                                : getAddressString(data?.mailingaddress)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                            Created On
                                                    </td>
                                                    <td>
                                                        {viewDateAndTime(data?.createdAt)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
Last Updated On
                                                    </td>
                                                    <td>
                                                        { viewDateAndTime(data?.updatedAt)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem>
                                <div className='preview-box'>
                                    <div className="preview-heading">
                                        <p>Company Admin Details  </p>
                                    </div>
                                    <div className='preview-body' >
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Email
                                                    </td>
                                                    <td>
                                                        <Link href={'mailto:' + admin?.ContactPointEmailValue} color='brand.500'>
                                                            {admin?.ContactPointEmailValue || 'N/A'}
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Phone
                                                    </td>
                                                    <td>
                                                        {viewMobile(admin?.ContactPointTelephoneValue)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>

                                        Actions
                                                    </td>

                                                    <td>
                                                        <Link href={'/organization/company/stats/lender/' + admin?._id} color='brand.500'>

                                                    View Admin Details
                                                        </Link>
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='preview-box'>
                                    <div className="preview-heading">
                                        <p>Company State Licenses  </p>
                                    </div>
                                    <div className='preview-body' >
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {
                                                            (data && data?.Statelicenses?.map((state,index)=>(
                                                                <Tag colorScheme='teal' key={index} m={2}>{state.label}</Tag>
                                                            ))) ||
                                                    (
                                                        <>
                                                        Doesn&apos;t have any state license
                                                        </>)
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </GridItem>
                        </Grid>
                    </React.Fragment>
                )
                : <NoDataCard label={'No Details Available'}/>;
};

export default CompanyDetails;
