/** ********************************** CONSTANTS  ************************** */
import { MARKETPLACE_ROLES_ACCESS , MARKETPLACE_APPLICATION_ACTIONS , BID_MARKETPLACE_ACTIONS } from 'constants/marketplace-actions';
import { BID_ACTIVE_STATUS , BID_STATUS } from 'constants/marketplace-bid';
import { CONSUMER_ROLE } from 'constants/index';
import { MARKETPLACE_APPLICATION_STATUS } from 'constants/marketplace-default';

/** ************************************* HELPERS  **************** */
import { getLocalROLE } from './token';
import { getStateName } from 'utils/address';
import { LOAN_PURPOSE } from 'constants/Select-constants';

/*
 todo: get disabled status of  buttons on consumer and lender marketplace
*/
export const getMarketplaceDisabledStatus = (value , ROLE)=>{
    if (value) {
        if (ROLE) {
            if (MARKETPLACE_ROLES_ACCESS.includes(ROLE)) {
                if (MARKETPLACE_APPLICATION_ACTIONS[value]) {
                    return !MARKETPLACE_APPLICATION_ACTIONS[value]?.lender?.revoke || false;
                }
                return false;
            }
            return true;
        }
        return true;
    }
    return false;
};
export const getDisableStatusAccrdingToRole = (ROLE)=>{
    if (ROLE) {
        if (MARKETPLACE_ROLES_ACCESS.includes(ROLE)) {
            return false;
        }
        return true;
    }
    return true;
};

/*
** todo get application id
*/
export const getApplicationIdView = (data)=>{
    if (data) {
        if (data?.applicationId) {
            return data?.applicationId?.applicationId;
        }
        return 'N/A';
    }
    return 'N/A';
};

export const isMarketplaceRole = (ROLE)=>{
    if (ROLE) {
        if (MARKETPLACE_ROLES_ACCESS.includes(ROLE)) {
            return true;
        }
        return false;
    }
    return false;
};
export const getActionStateOfApplication = (STATUS, ACTION)=>{
    if (STATUS) {
        const ROLE = getLocalROLE();
        if (MARKETPLACE_ROLES_ACCESS.includes(ROLE)) {
            if (ROLE === CONSUMER_ROLE) {
                return MARKETPLACE_APPLICATION_ACTIONS?.[STATUS]?.consumer.includes(ACTION);
            }
            return MARKETPLACE_APPLICATION_ACTIONS?.[STATUS]?.lender.includes(ACTION);
        }
        return false;
    }
    return false;
};

export const isbidActive = (bidStatus)=>{
    if (bidStatus === BID_ACTIVE_STATUS) {
        return true;
    }
    return false;
};

/**
 *  TODO : when the usertype lender will perform  action  on the application
 * 1. If the ROLE  should have the access to the marketplace actions
 * 2. If the bid  status ACTIVE
 * 3. The person who made the bid should revoke the bid
 * @param {*} userData
 * @param {*} bidData
 * @param {*} ACTION
 * @returns false
 */

export const getBidActionLender = (userData , bidData, ACTION)=>{
    let ROLE = userData?.role?.roleName;
    let lenderId = userData?._id;
    let biddedLender = bidData?.lid;
    let STATUS = bidData?.bidStatus;

    if (STATUS && ROLE && STATUS === BID_ACTIVE_STATUS && lenderId === biddedLender) {
        const isMarketrole = isMarketplaceRole(ROLE);
        if (isMarketrole) {
            if (MARKETPLACE_ROLES_ACCESS.includes(ROLE)) {
                return BID_MARKETPLACE_ACTIONS?.[STATUS]?.lender.includes(ACTION);
            }
            return false;
        }
        return false;
    }
    return false;
};

export const getBidActionConsumer = (bidData , ACTION)=>{
    let STATUS = bidData?.bidStatus;
    if (STATUS) {
        return BID_MARKETPLACE_ACTIONS?.[STATUS]?.consumer.includes(ACTION) || false;
    }
    return false;
};

export const getValueArray = (data)=>{
    if (data && data.length && data.length > 0) {
        data = data.map((row)=> row.value);
        return data;
    }
    return [];
};
export const getStateLicenseList = (data)=>{
    if (data && data.length && data.length > 0) {
        const final = data.map((value)=> {
            const label = getStateName(value);
            return { label, value };
        });
        return final;
    }
    return [];
};

export const getMarketplaceApplicationStatusLabel = (STATUS)=>{
    if (STATUS) {
        const record = MARKETPLACE_APPLICATION_STATUS[STATUS];
        return record?.label || 'N/A';
    }
    return null;
};
export const getBidAlertMessage = (STATUS)=>{
    if (STATUS) {
        const record = BID_STATUS[STATUS];
        return record || null;
    }
    return null;
};
export const getLoanPurposeView = (value)=>{
    let element = LOAN_PURPOSE.find((row)=> row.value === value);
    return element?.label || '';
};

export const getQueryString = (obj)=>{
    let str = '';

    for (let key in obj) {
        if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
            str += `${key}=${obj[key]}`;
        }
    }
    return str;
};
export const isLenderHasLicense = (state , userdata)=>{
    if (state && userdata && userdata?.Statelicenses) {
        if (userdata?.Statelicenses.includes(state)) {
            return true;
        }
        return false;
    }
    return false;
};
