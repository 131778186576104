import React from 'react';

/** ************************ COMPONENTS  ***********************8 */
import { Button,Slide , Textarea , Modal, ModalOverlay , ModalHeader , ModalCloseButton , ModalBody , ModalFooter, ModalContent, Text , FormControl } from '@chakra-ui/react';
/** *********************** FORM  FIELDS  **************88 */
import { Formik,Field, Form } from 'formik';
import { useApprovalActionMutation } from 'reducers/company.service';
import { CompanyActionModalScheme } from 'validations';
/** ************************ HELPERS  **************** */
import { APPROVE_ACTION } from 'constants/admin-actions';

function Companyaction({ ACTION, onClose,row , isOpen }) {
    const [handleApprove , { isLoading }] = useApprovalActionMutation();
    const handleSuccess = (values)=>{
        handleApprove({ id: row?._id , body: { action: ACTION , ...values } }).unwrap().then(()=>{
            onClose();
        });
    };
    return (
        <Slide direction='top' in={isOpen}>
            <Modal isCentered blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <Formik
                    initialValues={{
                        message: ''
                    }}
                    onSubmit={ handleSuccess}
                    enableReinitialize={true}
                    validator={() => ({})}
                    validationSchema={CompanyActionModalScheme}
                >
                    {({ handleSubmit, values }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                name="user-edit-form"
                                className="full-width"
                            >
                                <ModalContent>
                                    <ModalHeader>{ACTION === APPROVE_ACTION ? 'Approve Company' : 'Reject Request' }</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Text fontWeight='bold' mb='1rem'>
             Are  you  sure  wanted to {ACTION === APPROVE_ACTION ? 'Approve' : 'Reject' } request?
                                        </Text>
                                        {
                                            ACTION === APPROVE_ACTION && <Text>
            Once your  company get&apos;s approve company can  perform actions on marketplace and create there branches.
                                            </Text>
                                        }
                                        <br />
                                        <FormControl w="100%">
                                            <Field
                                                as={Textarea}
                                                isRequired={false}
                                                fontSize="sm"
                                                ms={{ base: '0px', md: '0px' }}
                                                type="text"

                                                name="message"
                                                placeholder="Enter message for lender..."
                                                mb="12px"
                                                fontWeight="500"
                                                size="lg"
                                            />
                                        </FormControl>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button mr={3} onClick={onClose}>
                            Close
                                        </Button>
                                        <Button type='submit' isLoading={isLoading} loadingText={'Sending.. '} variant='brand'>
                                            {ACTION === APPROVE_ACTION ? 'Approve' : 'Reject'}
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Form>
                        );
                    }
                    }
                </Formik>
            </Modal>
        </Slide>
    );
}

export default React.memo(Companyaction);
