import { mode } from '@chakra-ui/theme-tools';
const Card = {
    baseStyle: (props) => ({
        p: '20px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
        borderRadius: '5px',
        borderWidth: '1px',
        borderColor: mode('secondaryGray.100', 'whiteAlpha.100')(props),
        minWidth: '0px',
        wordWrap: 'break-word',
        bg: mode('#ffffff', 'navy.800')(props),
        backgroundClip: 'border-box'
    })
};
const Box = {
    baseStyle: (props) => ({
        pt: props.solid ? { base: '130px', md: '80px', xl: '65px' } : {}
    })
};

export const CardComponent = {
    components: {
        Card,
        Box
    }
};
