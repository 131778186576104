export const viewFullName = (data = {})=>{
    if (data) {
        let suffix = data?.SuffixName ? data?.SuffixName + ' ' : '';
        let first = data?.FirstName ? data?.FirstName + ' ' : '';
        let middle = data?.MiddleName ? data?.MiddleName + ' ' : '';
        let last = data?.LastName ? data?.LastName + ' ' : '';
        return suffix + first + middle + last;
    } else {
        return 'N/A';
    }
};

export const viewAvatarName = (data) => {
    let first = '';
    let last = '';
    if (data && data.FirstName && data.LastName) {
        first = data.FirstName[0];
        last = data.LastName[0];
        return first.toUpperCase() + last.toUpperCase();
    }
    if (data?.ContactPointEmailValue) {
        first = data?.ContactPointEmailValue[0];
        last = data?.ContactPointEmailValue[1];
        return first.toUpperCase() + last.toUpperCase();
    }
    return 'N/A';
};

export const getOcrFirstName = (name)=>{
    let array = [];
    if (name && name.length > 2) {
        if (name.includes(' ')) {
            array = name.split(' ', 1);
            return array[0] || '';
        } else if (name.includes('\n')) {
            array = name.split('\n', 1);
            return array[0] || '';
        } else {
            return name;
        }
    }
    return name;
};
export const getOcrLastName = (name)=>{
    let array = [];
    if (name && name.length > 2) {
        if (name.includes(' ')) {
            array = name.split(' ', 2);
            return array[1] || '';
        } else if (name.includes('\n')) {
            array = name.split('\n', 2);
            return array[1] || '';
        } else {
            return name;
        }
    }
    return name;
};
